import React, { useState, useEffect } from 'react';
import { LanguageContext } from '../../../LanguageContext';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetch_order_information, crud_order } from '../../API/Orders_API';
export const Orderscontext = React.createContext();
export const Orderscontext_provider = (props) => {
    const { lang, langdetect } = React.useContext(LanguageContext);
    const queryClient = useQueryClient();
    const [functypeparamcontext, setfunctypeparamcontext] = useState('');
    const [ordergenpaymentsContext, setordergenpaymentsContext] = useState([]);
    const [orderpayload_context, setorderpayload_context] = useState({
        functype: '',
        orderid: '',
        customerid: '',
        ordernotes: '',
        orderitems: [], // { productid: '', isvariant: '', variantid: '' }
        fullfilmstatus: '',
        totalitemsprice: 0,
        totalafterdiscount: 0,
        discountfees: 0,
        paidstatus: '',
        orderstatuslogid: '',
        ordersrctype: lang.webapp,
        country_id: '',
        state_id: '',
        city_id: '',
        zonename: '',
        zoneprice: '',
        address: '',
        paymentmethod: 'cod',
        promocodename: '',
        promocodediscount: '',
        haspromocode: 0,
    });
    const fetch_order_information_query = useQuery(
        ['fetch_order_information_query' + orderpayload_context.orderid],
        () =>
            fetch_order_information({
                orderid: orderpayload_context.orderid,
            }),
        { keepPreviousData: true, staleTime: 500000, enabled: functypeparamcontext == 'showorder' && orderpayload_context.orderid.length != 0 ? true : false },
    );
    const ordersummaryupdatercontext = (temporderpayload_context) => {
        var totalitemsprice = 0;
        var discountfees = 0;
        var totalafterdiscount = 0;
        var zoneprice = 0;
        var totalordersummary = 0;
        var poinstused = 0;
        if (temporderpayload_context.zonename != null && temporderpayload_context.zonename.length != 0) {
            zoneprice = temporderpayload_context.zoneprice;
        }
        temporderpayload_context.orderitems.forEach(function (arrayItem) {
            var totalpriceeachitem = arrayItem.quantity * arrayItem.finalprice;
            totalitemsprice = totalitemsprice + totalpriceeachitem;
        });
        if (temporderpayload_context.haspromocode == 1) {
            // if (temporderpayload_context.promocodevaluetype == 'percentage') {
            //     discountfees = parseFloat(totalitemsprice) * (parseFloat(temporderpayload_context.promocodediscount) / 100);
            // } else if (temporderpayload_context.promocodevaluetype == 'value') {
            //     discountfees = temporderpayload_context.promocodediscount;
            // } else if (temporderpayload_context.promocodevaluetype == 'shippingfees') {
            //     discountfees = parseFloat(zoneprice);
            // }
            discountfees = temporderpayload_context.promocodediscountfees
        }
        if (temporderpayload_context?.usewalletpoints == 1) {
            poinstused = parseFloat(temporderpayload_context?.walletpointsused);
        }
        totalafterdiscount = totalitemsprice - poinstused - discountfees;
        totalordersummary = parseFloat(totalafterdiscount) + parseFloat(zoneprice) + parseFloat(temporderpayload_context?.orderextragenpayments?.totalvaluestobemodified ?? 0);
        totalordersummary = totalordersummary?.toFixed(2);
        temporderpayload_context.totalitemsprice = totalitemsprice;
        temporderpayload_context.discountfees = discountfees;
        temporderpayload_context.totalafterdiscount = totalafterdiscount;
        // alert(totalordersummary);
        temporderpayload_context.totalordersummary = totalordersummary;

        setorderpayload_context({ ...temporderpayload_context });
    };

    useEffect(() => {
        if (fetch_order_information_query.isSuccess) {
            if (fetch_order_information_query.data.data.status) {
                var temporderpayload_context = { ...orderpayload_context };
                temporderpayload_context = fetch_order_information_query.data.data;
                ordersummaryupdatercontext(temporderpayload_context);
                setorderpayload_context({ ...temporderpayload_context });
                setordergenpaymentsContext(fetch_order_information_query?.data?.data?.orderextragenpayments?.arrayofgenorderpayment);
            }
        }
    }, [fetch_order_information_query.isSuccess, fetch_order_information_query.data]);
    return (
        <Orderscontext.Provider
            value={{
                orderpayload_context,
                setorderpayload_context,
                ordersummaryupdatercontext,
                functypeparamcontext,
                setfunctypeparamcontext,
                fetch_order_information_query,
                setordergenpaymentsContext,
                ordergenpaymentsContext,
            }}
        >
            {props.children}
        </Orderscontext.Provider>
    );
};
