import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    close: 'close',
                    support_assistance_rating: 'How would you rate the support assistance you received?',
                    website_design_satisfaction: 'How satisfied are you with the design of your website?',
                    overall_website_satisfaction: 'Overall, how satisfied are you with the Tabex website?',
                    dashboard_usability: 'How user-friendly do you find the dashboard?',
                    mobile_application_selectbox: 'Do you have a mobile application?',
                    mobile_application_design_satisfaction: 'How satisfied are you with the design of your mobile application?',
                    overall_mobile_application_satisfaction: 'Overall, how satisfied are you with the Tabex mobile application?',
                    surveytitle: 'Tell Us How We’re Doing:',
                    surveytitle2: 'Customer Feedback Survey',

                    support: 'Support',
                    tables: 'Tables',
                    table: 'Table',
                    downloadqr: 'Download QR',
                    addtable: 'Add table',
                    tablename: 'Table Name',
                    youhavenotablesyet: 'You have no tables yet',
                    doyouwanttodeletethistable: 'Do you want to delete this table?',
                    edittable: 'Edit table',
                    deletetable: 'Delete table',
                    webandmob: 'Website & Mobile application',
                    shortdescription: 'Short Description',
                    totaltobeused: 'Total To Be Used',
                    totaltobeusedbyeachcustomer: 'Total to be used by each customer',
                    usedby: 'Used by',
                    customerandguests: 'Customers and Guests',
                    customersonly: 'Customers only',
                    percentage: 'Percentage',
                    value: 'Value',
                    sendtoallcustomers: 'Send to all customers',
                    sendtounregisteredusers: 'Send To Unregistered Users',
                    sendpushnotificationto: 'Send Push Notification To',
                    notificationcancelled: 'Notification Cancelled',
                    pleasewritetitlesndbody: 'Please Write Title And Body',
                    body: 'Body',
                    sendpushnotification: 'Send Push Notification',
                    walletlogs: 'Wallet Logs',
                    walletbalance: 'Wallet balance',
                    walletpoints: 'Wallet Points',
                    subtract: 'Subtract',
                    modifywallet: 'Modify Wallet',
                    pointstobe: 'Points to be',
                    added: 'Added',
                    subtracted: 'Subtracted',
                    issubscriptionactive: 'Is Subscription Active',
                    subscriptionname: 'Subscription name',
                    subscriptionstarts: 'Subscription Starts',
                    subscriptionends: 'Subscription Ends',
                    history: 'History',
                    starts: 'Starts',
                    expires: 'Expires',
                    subscribecustomer: 'Subscribe Customer',
                    choose: 'Choose',
                    pushnotification: 'Push Notification',
                    removeall: 'Remove All',
                    addall: 'Add All',
                    youhavesuccessfullysubscribed: 'You have successfully subscribed',
                    checksubscriptions: 'Check subscriptions',
                    failed: 'Failed',
                    upgradingsubscriptions: 'Upgrading Subscriptions',
                    packagefeesmonthly: 'Package Fees Monthly',
                    maxcustomers: 'Max customers',
                    nodata: 'No Data',
                    novendor: 'No Vendor',
                    productextrafields: 'Product Extra Fields',
                    subtitle: 'Sub Title',
                    addfield: 'Add Field',
                    duplicate: 'Duplicate',
                    areyousureyouwanttodeletethisfield: 'Are You Sure You Want To Delete This field',
                    showproduct: 'Show product',
                    overwritevariants: 'Overwrite Variants',
                    areyousureyouwanttoclear: 'Are you sure you want to clear?',
                    areyousureyouwanttoclearandkeepquantity: 'Are you sure you want to clear and keep quantity?',
                    setandclear: 'Set and clear',
                    set: 'Set',
                    productaffiliatepercentageprofit: 'Product Affiliate Percentage Profit %',
                    productcashbackvalue: 'Product Cashback value',
                    issubscriptionrequired: 'Is Subscription required',
                    is_max_product_ordered_related_tosubscription_date: 'Is the maximum quantity of the product ordered related to the subscription date?',
                    nosubscription: 'No Subscription',
                    subscriptionrequired: 'Subscription Required',
                    forcetocheckout: 'Force To Checkout',
                    donotforce: 'Do not force',
                    force: 'Force',
                    producttobesold: 'Product To Be Sold',
                    nottobesold: 'Not to be sold',
                    tobesold: 'To Be Sold',
                    productcanquantitychange: 'Product Can Quantity Change',
                    ifonlyproductremoveallshippingfees: 'If Only Product Remove All Shipping Fees',
                    setquantity: 'Set Quantity',
                    vendorquantity: 'Vendor Quantity',
                    orderedlogs: 'Ordered Logs',
                    valueindex: 'Value index',
                    quantityconditions: 'Quantity conditions',
                    pleasefillthemissingfields: 'Please fill the missing fields',
                    reviews: 'Reviews',
                    importproductvariants: 'Import product variants',
                    autogeneratevariants: 'Auto Generate Variants',
                    notauthorizedtoviewproducts: 'Not Authorized To View Products',
                    or: 'or',
                    generatevariants: 'Generate Variants',
                    showvariant: 'Show variant',
                    wecouldntfindyourproduct: 'We Couldnt Find Your Product',
                    areyousureyouwanttouploadimages: 'Are you sure you want to upload images?',
                    imagesuploadedsuccessfully: 'Images uploaded successfully',
                    pleasechooseimages: 'Please choose images',
                    maxMBperupload: 'Max 40 MB per upload.',
                    upload: 'Upload',
                    images: 'Images',
                    image: 'Image',
                    maxMBperimage: 'Max: 3MB per image',
                    totalimagessizeperupload: 'Total images size per upload',
                    imagenamestructure: 'Image name Structure',
                    imagealreadyexists: 'Image Already Exists',
                    import: 'Import',
                    imagestotalsize: 'Images total size',
                    notauthorizedtoviewcollections: 'Not Authorized To View Collections',
                    pleasefixtheerrorsbelowinordertouploadyourimages: 'Please fix the errors below in order to upload your images',
                    importparentcollections: 'Import parent collections',
                    importcategories: 'Import categories',
                    addaffiliate: 'Add Affiliate',
                    choosesecuritygroup: 'Choose Security Group',
                    nocompany: 'No company',
                    pickupaddress: 'Pickup address',
                    pickupcity: 'Pickup city',
                    copylink: 'Copy Link',
                    copy: 'Copy',
                    cancustomerdeleteorder: 'Can Customer Delete Order',
                    enableguestorders: 'Enable guest orders',
                    enableproductdailylimit: 'Enable product daily limit',
                    hideoutofstockproducts: 'Hide out of stock products',
                    automaticsendorderstocourier: 'Automatic send orders to courier',
                    minordervalue: 'Min.order value',
                    maxordervalue: 'Max.order value',
                    contactphonenumber: 'Contact Phone Nmber',
                    subscriptioncollections: 'Subscription Collections',

                    customerstatus: 'Customer Status',
                    customerdeleted: 'Customer Deleted',
                    customeractive: 'Customer Active',
                    fname: 'First name',
                    lname: 'Last name',
                    addgeneralpayment: 'Add payment',
                    subscription: 'Subscription',
                    confirmorder: 'Confirm Order',
                    cart: 'Cart',
                    clearcart: 'Clear cart',
                    addtocart: 'Add to cart',
                    pleasechoosevariant: 'Please Choose Variant',
                    pleasechoosequantity: 'Please choose quantity',
                    yourreceipt: 'Your receipt',
                    subscriptiontext1: "If you have any inquiries, please don't hesitate to call us.",
                    subscriptiontext2: 'For packages details, please visit',
                    annual: 'Annual',
                    Quarterly: 'Quarterly',
                    Bronze: 'Bronze',
                    Silver: 'Silver',
                    Gold: 'Gold',
                    Platinum: 'Platinum',
                    year: 'year',
                    month: 'Month',
                    packagename: 'Package name',
                    packages: 'Packages',
                    //clara
                    removesale: 'Remove Sale',
                    removecollection: 'Remove Colllection',
                    removevendor: 'Remove Vendor',
                    chooseaction: 'Choose Action',

                    errorinupdatinganalytics: 'Error In Updating Analytics',
                    analyticsupdatedsucessfully: 'Analytics Updated Successfully',
                    thisparentcollectionalreadyexists: 'This Parent Collection Already Exists',
                    categoriesupdatedsuccessfuly: 'Categories Updated Successfully',
                    logoedited: 'Logo Edited',
                    pleasefilltitleenglishfieldanddescriptioninenglish: 'Please Fill Title English Field and Description in English',
                    thisproductalreadyexists: 'This Product Already Exists',
                    collectionfilehasbeenimportedsuccessfully: 'Collection File Has Been Imported Successfully',
                    errorinimporting: 'Error In Importing',
                    pleasechoosefile: 'Please Choose File',
                    errorinsearchingcustomers: 'Error In Searching Customers',
                    errorinloggingout: 'Error In Logging Out.',
                    domainsettingsupdated: 'Domain Settings Updated',
                    statusisaddedsuccessfully: 'Status is added successfully',
                    statusiseditedsuccessfully: 'Status is edited successfully',
                    statusisdeletedsuccessfully: 'Status is deleted successfully',
                    pleasewritefilltheoptionfields: 'Please Write Fill The Option Fields',
                    allusersunselected: 'all users unselected',
                    areaoutofzone: 'Area Out Of Zone.',
                    pleaseilltheaddressinformation: 'Please Fill The Address Information',
                    thisareaisoutofyourzone: 'This Area Is Out Of Your Zone',
                    parentcollectionsupdatedsuccessfully: 'Parent Collections Updated Successfully',
                    errorinupdatingparentcollections: 'Error In Updating Parent Collections',
                    thiscollectionalreadyexists: 'This Collection Already Exists',
                    addname: 'Add Name',
                    maintagassignedtothisproduct: 'Main Tag Assigned To This Product',
                    tagaddedtoproduct: 'Tag Added To Product',
                    pleasechoosetag: 'Please Choose Tag',
                    pleasechooseproductsfirst: 'Please Choose Products First',
                    productshasbeenupdated: 'Products Has Been Updated',
                    errorinupdatingproducts: 'Error in Updating Products',
                    pleaseselectproductsfirst: 'Please Select Products First',
                    productsfilehasbeenimportedsuccessfully: 'Products File Has Been Imported Successfully',
                    pleasechoosefileorvendor: 'Please Choose File or Vendor',
                    errorinupdatingproducttags: 'Error In Updating Product Tags',
                    Productstagsupdated: 'Products Tags Updated',
                    pleasewritetagname: 'Please Write Tag Name',
                    errorinupdatingproductoptions: 'Error In Updating Product Options',
                    errorinchangingproductoptionvalues: 'Error In Changing Product Option Values',
                    optionvaluesupdated: 'Option Values Updated',
                    pleaseaddsecuritygroupname: 'Please Add Security Group Name',
                    errorinaddinginstitute: 'Error In Adding Institute',
                    errorineditinginstitute: 'Error In Editing Institute',
                    errorindeletinginstitute: 'Error In Deleting Institute',
                    insituteisaddedsucessfully: 'Institute is Added Successfully',
                    insituteiseditedsucessfully: 'Institute is Edited Successfully',
                    insituteisdeltedsucessfully: 'Institute is Deleted Successfully',

                    print: 'Print',
                    totalafterdiscount: 'Total After Discount',
                    parentcollectionlogo: 'Parent Collection Logo',
                    parentcollectioninformation: 'Parent Collection Information',
                    parentcollectionstatus: 'Parent Collection Status',
                    collectionsinparent: 'Collections in Parent',
                    deleteparentcollections: 'Delete Parent Collections',

                    categoryinformation: 'Category Information',
                    parentcollectionname: ' Parent Collection Name',
                    parentcollectiondescription: ' Parent Collection Description',
                    nomoreproducts: 'No More Products',

                    nomoreordersfound: 'No More Orders Found',

                    addparentcollection: 'Add Parent Collection',
                    editparentcollection: 'Edit Parent Collection',
                    viewall: 'View all',
                    viewnostatus: 'View no status',
                    searchresults: 'Search Results',

                    recentactivityforallorders: 'Recent activity for all orders',
                    connecttoasubdomain: ' Connect to a sub domain',
                    connecttoanexternaldomain: 'Connect to an external domain',
                    subdomain: 'sub domain',
                    externaldomain: 'external domain',
                    updatedomainsettings: 'Update Domain Settings',
                    orderid: 'Order ID',

                    setvalues: 'Set Values',
                    assignoptionfirst: 'Assign Option First',
                    stategov: 'State/Governorate',

                    importcollections: 'Import Collections',
                    nameinenglish: 'name in english',
                    nameinarabic: 'name in arabic',
                    update: 'Update',
                    updatestatusto: 'Update Status To',
                    commentsbox: 'Comments Box',
                    variant: 'Variant',
                    institutename: 'Institute Name',
                    userid: 'User ID',
                    addinstitute: 'Add Institute',
                    editinstitute: 'Edit Institute',

                    tableisempty: 'Table is Empty',

                    tabexshops: 'Tabex',
                    // Side Nav & Page Titles
                    sidnav_subscription: 'Subscription',

                    sidenav_subscriptionscustomers: "Client's Subscriptions",
                    sidenav_onlinegeneralpayments: 'General online payments',
                    sidenav_home: 'Home',
                    sidenav_dashboard: 'Dashboard',
                    sidenav_stores: 'Stores',
                    sidenav_websites: 'Websites',
                    sidenav_orders: 'Orders',
                    sidenav_orderstracking: 'Orders Tracking',
                    sidenav_abandonedcarts: 'Abandoned Carts',

                    sidenav_products: 'Products',
                    sidenav_collections: 'Collections',
                    sidenav_customers: 'Customers',
                    sidenav_promocodes: 'Promo Codes',
                    sidenav_pricing: 'Pricing',
                    sidenav_design: 'Design',
                    sidenav_websitetemplates: 'Website Templates',
                    sidenav_mobileapptemplates: 'Mobile Templates',
                    sidenav_domain: 'Domain',
                    sidenav_analytics: 'Analytics',
                    sidenav_policies: 'Policies',
                    sidenav_generalsettings: 'General Settings',
                    sidenav_onlinepayment: 'Online Payment',
                    sidenav_shipping: 'Shipping',
                    sidenav_institutesettings: 'Institute Settings',
                    sidenav_branches: 'Branches',
                    pagetitle_showorder: 'Show Order',
                    sidenav_paymentmethods: 'Payment Methods',
                    sidenav_addnewproduct: 'Add New Product',
                    sidenav_mobileapplication: 'Mobile App',
                    sidenav_affiliates: 'Affiliates',
                    sidenav_users: 'Users',
                    sidenav_smsservice: 'SMS Service',
                    sidenav_securitylayers: 'Security Layers',
                    sidenav_vendors: 'Vendors',
                    sidenav_couriers: 'Couriers',
                    sidenav_reports: 'Reports',
                    sidenav_integrations: 'Integrations',

                    // Side Nav Titles
                    sidenav_dashboards: 'Dashboards',
                    sidenav_management: 'Management',
                    sidenav_onlinestore: 'Online Store',
                    sidenav_settings: 'Settings',

                    nosecutitylayersfound: 'No Security Layers Found',

                    // Generall
                    ssn: 'Social Security Number',
                    showinfilter: 'Show in Filter',
                    chosentags: 'Chosen Tags',
                    tableformat: 'Table Format',
                    cities: 'Cities',
                    state: 'State',
                    nostatusfound: 'No Status Found',
                    totalusers: 'Total Users',
                    from: 'From',
                    to: 'To',
                    filter: 'Filter',
                    showfilter: 'Show Filter',
                    searchvendors: 'Search Vendors',
                    searchcollections: 'Search Collections',
                    parentcollections: 'Parent Collections',
                    salestatus: 'Sale status',
                    hassale: 'Has Sale',
                    nosale: 'No Sale',
                    all: 'All',
                    details: 'Details',
                    products: 'Products',
                    chooseoption: 'Choose Option',
                    totalorders: 'Total Number Of Orders',
                    totalorders2: 'Total Orders',
                    totalsales: 'Total Sales',
                    lowstockproducts: 'Low Stock Prodcuts',
                    outofzone: 'Out Of Zone',
                    actiontype: 'Action Type',
                    clearfilters: 'Clear Filters',
                    online: 'Online',
                    apply: 'Apply',
                    remove: 'Remove',
                    notes: 'Notes',
                    domain: 'Domain',
                    source: 'Source',
                    byyou: 'by you',
                    customer: 'Customer',
                    guest: 'Guest',
                    default: 'Default',
                    choose: 'Choose',
                    done: 'Done',
                    image: 'Image',
                    deleteall: 'Delete All',
                    disclaimer: 'Disclaimer',
                    updatelogo: 'Update Logo',
                    expirydate: 'Expiry Date',
                    institutes: 'Institutes',
                    createdon: 'Created On',
                    mobile: 'Mobile',
                    country: 'Country',
                    address: 'Address',
                    send: 'Send',
                    select: 'Select',
                    password: 'Password',
                    note: 'Note',
                    selectall: 'Select All',
                    unselectall: 'Unselect All',
                    title: 'Title',
                    sale: 'Sale',
                    price: 'Price',
                    saleprice: 'Sale Price',
                    onsale: 'On Sale',
                    offsale: 'Off Sale',
                    languages: 'Languages',
                    emailto: 'Email to',
                    paymentdetails: 'Payment Details',
                    archive: 'Archive',
                    branch: 'Branch',
                    name: 'Name',
                    email: 'E-mail',
                    phonenumber: 'Phone Number',
                    edit: 'Edit',
                    delete: 'Delete',
                    description: 'Description',
                    ifyespleaswrite: 'If Yes, Please Write',
                    pleasewrite: 'Please Write',
                    warning: 'Warning',
                    search: 'Search...',
                    total: 'Total',
                    date: 'Date',
                    manage: 'Manage',
                    egp: 'EGP',
                    show: 'Show',
                    notadded: 'Not Added',
                    back: 'Back',
                    save: 'Save',
                    submit: 'Submit',
                    addtranslation: 'Add translation',
                    removetranslation: 'Remove Translation',
                    contactinformation: 'Contact Information',
                    add: 'Add',
                    arabictranslaion: 'Arabic Translation',
                    englishtranslation: 'English Translation',
                    for: 'For',
                    option: 'Option',
                    options: 'Options',
                    done: 'Done',
                    hello: 'Hello',
                    arabic: 'Arabic',
                    english: 'English',
                    livepreview: 'Live Preview',
                    subscribe: 'Subscribe',
                    logout: 'Logout',
                    language: 'Language',
                    allproducts: 'All Products',
                    chosenproducts: 'Chosen Products',
                    pleasecompletethemissingfields: 'Please Complete The Missing Fields',
                    product_s_: 'Product(s)',
                    company: 'Company',

                    // Status
                    securitygroup: 'Security Group',
                    status: 'Status',
                    active: 'Active',
                    draft: 'Draft',
                    notactive: 'Inactive',
                    enable: 'Enable',
                    disable: 'Disable',
                    required: 'Required',
                    optional: 'Optional',
                    visible: 'Visible',
                    hidden: 'Hidden',
                    pending: 'Pending',
                    waitingforpickup: 'Waiting For Pick Up',
                    pickedup: 'Picked Up',

                    inputfield: 'Input Field',
                    selectbox: 'Selectbox',

                    // Welcome Page
                    manageyourstores: 'Manage Your Stores,',
                    createnewstore: 'Create New Store',
                    youhavenotcreatedanystoresyet: 'You have not created any stores yet.',
                    yourstores: 'Your Stores:',
                    storename: 'Store Name',
                    freetrial: 'Free Trial',
                    dashboardhome: 'Dashboard',
                    upgrade: 'Upgrade',
                    expired: 'Expired',
                    type: 'Type',
                    currency: 'Currency',
                    createstore: 'Create Store',
                    noinstitutesyet: "You don't have any institutes yet",
                    createnewinst: 'Create New Institute',

                    // Home
                    golivenow: 'Go Live Now!',
                    golivedesc: "Don't miss another day without being online!",
                    stepstocomplete: 'Steps To Complete',
                    recentactivity: 'Recent Activity',
                    homecard_createyourstore: 'Create Your Store',
                    homecard_addproducts: 'Add Products',
                    homecard_addcollections: 'Add Collections',
                    homecard_customizewebsite: 'Customize your website',
                    homecard_addshippingdetails: 'Add shipping details',
                    homecard_golive: 'Go Live!',
                    subscribenow: 'Subscribe',

                    // Home2
                    sales: 'Sales',
                    totalsalestoday: 'Total Sales Today',
                    profit: 'Profit',
                    ratioperday: 'Ratio Per Day',
                    totalorderstoday: 'Total Orders Today',
                    visits: 'Visits',
                    totalvisitsfortoday: "Total Today's Visits",

                    recentorders: 'Recent Orders',
                    quickactions: 'Quick Actions',
                    actions: 'Actions',
                    addcollection: 'Add Collection',
                    editcollection: 'Edit Collection',

                    // Branches
                    addbranch: 'Add Branch',
                    editbranch: 'Edit Branch',
                    deletebranch: 'Delete Branch',
                    nobranchesyet: 'No branches yet',
                    pleaseaddbranchname: 'Please add branch name',

                    // Shipping
                    updateshippingandaddressdetails: 'Update Adderss & Shipping Details',
                    shipping: 'Shipping',
                    chosenzones: 'Chosen Zones',
                    addzone: 'Add Zone',
                    zonename: 'Zone Name',
                    zonerateprice: 'Zone Rate Price',
                    zoneratesaleprice: 'Zone Rate Sale Price',
                    countries: 'Countries',
                    editzone: 'Edit Zone',
                    shippingmethods: 'Shipping Methods',
                    deletezone: 'Delete Zone',
                    youhavenotaddedanyzonesyet: 'No Zones have been added yet',
                    zonestates: 'Zone States',
                    shippingprice: 'Shipping Rate',
                    shippingsaleprice: 'Shipping Sale Price',
                    selectedzones: 'Selected Zones',
                    governorate: 'Governorate',
                    city: 'City',

                    // Couriers
                    zoneid: 'Zone ID',
                    zonename: 'Zone Name',
                    zonecities: 'Zone Cities',
                    allcouriers: 'All Couriers',
                    addcourier: 'Add New Courier',
                    couriername: 'Courier Name',
                    zones: 'Zones',
                    addnewcourier: 'Add New Courier',
                    editcourier: 'Edit Courier Information',
                    deletecourier: 'Delete Courier',
                    nocouriersyet: 'No Couriers yet',

                    // Products
                    tagname: 'Tag Name',
                    addtag: 'Add Tag',
                    edittag: 'Edit Tag',
                    chooseproducttags: 'Choose  Tags',
                    productoptionmanagement: ' Options Management',
                    measurementunit: 'Measurement Unit',
                    editproductinformation: 'Edit  Information',
                    productinformation: ' Information',
                    productgallery: ' Gallery',
                    inventorysettings: 'Inventory Settings',
                    stockandpricing: 'Stock & Pricing',
                    producttags: 'Tags',
                    importproducts: 'Import Products',
                    productstags: 'Products Tags',
                    productslogs: 'Products Logs',
                    thresholdquantity: 'Threshold quantity',
                    assignproductoptions: 'Assign  Options',
                    assign: 'Assign',
                    deassign: 'De-assign',
                    assignvalues: 'Assign Values',
                    // assignoptionstoproduct: 'Assign Option To This Product',
                    doyouwanttodeletethisproduct: 'Do You Want To Delete This Product?',
                    addproducts: 'Add Products',
                    productvariants: 'Variants',
                    showproductvariants: 'Show Variants',
                    noproductsyet: 'You have no products',
                    editproduct: 'Edit Product',
                    addproduct: 'Add Product',
                    deleteproduct: 'Delete',
                    addnewproduct: 'Add New Product',
                    filterbyavailability: 'Filter By Availability',
                    filterby: 'Filter By',
                    unlisted: 'Unlisted',
                    productname: 'Product Name',
                    collection: 'Collection',
                    inventorystatus: 'Inventory Status',
                    limitedquantity: 'Limited Quantity',
                    alwaysavailable: 'Always Available',
                    exportproductsasCSV: 'Export Products As CSV',
                    export: 'Export',
                    exportproducts: 'Export Products',
                    currentpage: 'Current Page',
                    selectedproducts: 'Selected Products',
                    filteredproducts: 'Filtered Products',
                    productavailability: 'Availability',
                    changeproductavailability: 'Change Product Availability',
                    doyouwantchangeprodavailto: 'Do You Want To Change Product Availability To',
                    changeprodavailtodisclaimer1: 'Changing product availavility to',
                    productinformation: 'Information ',
                    inventory: 'Inventory',
                    inventorysettings: 'Inventory Settings',
                    storagekeepingunit: 'Storage Keeping Unit',
                    sku: 'SKU',
                    updateproductinformation: 'Update Information',
                    uploadproductimages: 'Upload Images',
                    productgallery: ' Gallery',
                    noimages: 'No Images',
                    defaultimagedisclaimer: 'Choose default image by clicking on the image.',
                    updateinventory: 'Update Inventory',
                    updateinventorysettings: 'Update Settings Inventory',
                    minimumquantity: 'Minimum Quantity',
                    defaultpricing: 'Default Pricing',
                    optional_ifthereisanysale_: 'Optional (If There Is Any Sale)',
                    updatepricing: 'Update Pricing',
                    totalquantity: 'Total Quantity',
                    orderedquantity: 'Ordered Quantity',
                    currentquantity: 'Current Quantity',
                    updatequantity: 'Update Quantity',
                    newquantity: 'New Quantity',
                    changetype: 'Change Type',
                    updatequantity: 'Update Quantity',
                    product: 'Product',
                    // Options
                    variantsku: 'Variant SKU',
                    addoptionsdisclaimer: 'You Need To Add Product Options First',
                    productoptions: 'Product Options',
                    addproductoptions: 'Add Product Options',
                    editproductoptions: 'Edit Product Options',
                    addoption: 'Add Option',
                    editoption: 'Edit Option',
                    optionname: 'Option Name',
                    optionvalue: 'Option Value',
                    optionvalues: 'Option Values',
                    productvariatname: 'Product Variant Name',
                    productvariatoptions: 'Product Variant Options',
                    pressentertosubmitvalues: 'Press Enter To Submit Value',
                    waittillvalueisadded: 'Please Wait. We Are Adding Your Value.',
                    pleaseaddoptionnameandvalues: 'Please Add Option Name and Values',
                    optionsaleprice: 'Option Sale Price',
                    optionprice: 'Option Price',
                    // Variants
                    enablevariants: 'Enable Variants',
                    disablevariants: 'Disable Variants',
                    novariantsyet: 'You have not added any variants yet',
                    choosevariantimage: 'Choose Variant Image',
                    variantimage: 'Variant Image',
                    variants: 'Variants',
                    variant2: 'Variants',
                    variantpricing: 'Variant Pricing',
                    variantprice: 'Variant Price',
                    variantsaleprice: 'Variant Sale Price',
                    updatevariant: 'Update Variant',
                    addvariant: 'Add Variant',
                    addproductvariants: 'Add Product Variants',
                    editproductvariants: 'Update Product Variants',
                    currentvariant: 'Current Variant',
                    ownernotes: 'Company Notes',
                    noproductimages: 'No Product Images',
                    noproductimagesdisclaimer: 'Please Upload Images For The Product First',
                    // Collections
                    choosecollection: 'Choose Collection',
                    allcollections: 'All Collections',
                    collectiondescription: 'Collection Description',
                    addnewcollection: 'Add New Collection',
                    nocollectionsyet: "You Haven't added any collections yet",
                    collectioninformation: 'Collection Information',
                    collectionname: 'Collection Name',
                    collectionlogo: 'Collection Logo',
                    collectionproducts: 'Collection Products',
                    completecollectioninfo: 'Please Complete all Collection Information First',
                    uploadlogo: 'Upload Logo',
                    addcollection: 'Add Collection',
                    editcollection: 'Edit Collection',
                    deletecollection: 'Delete Collection',
                    numberofproducts: 'Number Of Products',

                    // Orders & Customers
                    addressandshipping: 'Address & Shipping',
                    doyouwanttodeletecustomer: 'Do You Want To Delete this Customer?',
                    logs: 'all Logs',
                    updateorderpayment: 'Update Order Payment',
                    orderitems: 'Order Items',
                    ordersummary: 'Order Summary',
                    updateaddress: 'Update Address',
                    updatestatus: 'Update Status',
                    trackactivity: 'Track Activity',
                    viewordersnotauth: ' Not Authorized To View Orders',
                    noordersyet: ' you have no orders yet',
                    resultsfromsearch: 'Results From Search',
                    orderactivity: 'Order Activity',
                    searchbyvendors: 'Search By Vendors',
                    totalspent: 'Total Spent',
                    numberoforders: 'Number Of Orders',
                    orderstatus: 'Order Status',
                    chooseorderstatus: 'Choose Order Status',
                    customerhavenoordersyet: 'No orders found',
                    noorderbythiscourier: 'No orders found by this courier',
                    previousorders: 'Previous Orders',
                    paymentfulfillstatus: 'Payment & Fulfillment Status',
                    customerinformation: 'Customer Information',
                    shippingaddress: 'Shipping Address',
                    billingaddress: 'Billing Address',
                    allorders: 'All Orders',
                    choosecustomer: 'Choose Customer',
                    guest: 'Guest',
                    chooseoptions: 'Choose Options',
                    havenotaddedproducts: 'You havenot added any products yet',
                    addquantity: 'Add Quantity',
                    quantity: 'Quantity',
                    addneworder: 'Add New Order',
                    addorder: 'Add Order',
                    filterbypaymentstatus: 'Filter By Payment Status',
                    filterbyfulfillmentstatus: 'Filter By Fulfilment Status',
                    searchordernum: 'Search By Order Number',
                    ordernumber: 'Order #',
                    customername: 'Customer Name',
                    items: 'Items',
                    orderdetails: 'Order Details',
                    choosepaymentstatus: 'Choose Payment Status',
                    choosefulfillmentstatus: 'Choose Fulfillment Status',
                    fulfillorder: 'Fulfill Order',
                    cancelfulfillment: 'Cancel Fulfillment',
                    cancelfulfillmentconfirm: 'Do you want to cancel this order fulfillemnt?',
                    editfulfillmentstatus: 'Edit Fulfillment Status',
                    dependonoption: 'Depends On Options',
                    orderno: 'Order No',
                    ordernumber: 'Order Number',
                    item: 'Item',
                    subtotal: 'Sub Total',
                    shippingrate: 'Shipping Rate',
                    discount: 'Discount',
                    paidamount: 'Paid Amount',
                    editorderstatus: 'Edit Order Status',
                    deleteorderstatus: 'Delete Order Status',
                    addorderstatus: 'Add Order Status',
                    titleinen: 'Title in English',
                    titleinar: 'Title in Arabic',
                    bgcolor: 'Background Color',
                    forstaff: 'For Staff',
                    forcustomers: 'For Customers',
                    customers: 'Customers',
                    staff: 'Staff',
                    additems: 'Add Items',
                    additem: 'Add Item',
                    removeitem: 'Remove Item',
                    edititem: 'Edit Item',
                    customernotes: 'Customer Notes',
                    ordertrackingactivity: 'Tracking Activity',
                    orderpaidstatusupdated: 'Order Paid Status Updated',
                    deleteorder: 'Delete Order',
                    removecustomer: 'Remove Customer',
                    removecustomerconfirmation: 'Do You Want To Remove This Customer?',
                    numberoforders: 'Orders',
                    totalspending: 'Total Spending',
                    youhavenotaddedanycustomersyet: 'You have not added any customers yet',
                    addnewcustomer: 'Add New Customer',
                    editcustomer: 'Edit Customer Information',
                    allcustomers: 'All Customers',
                    deletecustomer: 'Delete Customer',
                    deletewarningtext: 'Warning: all customer information and orders will be completely deleted',
                    showcustomerinfo: 'Show Customer Information',
                    youhavenptaddedproductsinorder: 'You havenot added any products in the order yet',
                    doyouwanttodelete: 'Do You Want To Delete',
                    fromordernum: 'From Order',
                    order: 'Order',

                    // Promocodes
                    promocode: 'Promo Code',
                    addpromocode: 'Add Promo Code',
                    youhavenocouponsyet: 'You Havenot added any promo codes yet',
                    couponname: 'Promo code Name',
                    usage: 'Usage',
                    doyouwanttodeletethiscoupon: 'Do you want to delete this Promo Code ?',
                    editpromocode: 'Edit Promo Code',
                    deletepromocode: 'Delete Promo Code',

                    // Policies
                    privacypolicy: 'Privacy Policy',
                    privacypolicycontent: 'Privacy Policy Content',
                    termsandconditions: 'Terms & Conditions',
                    refundpolicy: 'Refund Policy',
                    shippingpolicy: 'Shipping Policy',
                    policyinformation: 'Policy Information',
                    deletepolicytranswarningtext: 'Do you want to delete page translation?',
                    generatetemplate: 'Generate Template',

                    // Institute Settings
                    storeinformation: 'Store Information',
                    storename: 'Store Name',
                    storetype: 'Store Type',
                    storeindustry: 'Store Industry',
                    storelogo: 'Store Logo',
                    storefavouriteicon: 'Store Favourite Icon',
                    paymentsettings: 'Payment Settings',
                    updatecontactinformation: 'Update Contact Information',
                    updatestoreinformation: 'Update Store Information',
                    updatestorelogo: 'Update Store Logo',
                    updatestorefavicon: 'Update Store Favicon',
                    contactinfodesc: 'This is how your customers will contact you',
                    // Signup data
                    addnewdata: 'Add New Data',
                    editsignupdata: 'Edit Data',
                    deletesignupdata: 'Delete Data',
                    signupcustomerinfo: 'Signup Customer Information',
                    // Login
                    welcomeback: 'Welcome ',
                    totabexseries: 'to tabex series',
                    login: 'Login',
                    forgotpassword: 'Forgot Password?',
                    wrongemailorpassword: 'Wrong email or password',
                    // Payment
                    payment: 'Payment',
                    deletepaymentmethod: 'Delete Payment Method',
                    cod: 'Cash On Delivery',
                    paymentmethod: 'Payment Method',
                    paymentmethods: 'Payment Methods',
                    onlinepaymentsettings: 'online payment method settings',
                    emailsettings: 'Email Settings',
                    choosepaymentmethod: 'Choose Payment Method',
                    integrationinstructions: 'Intergration Instructions',
                    paymenthassuccessfullydeleted: 'Payment Method is successfully deleted',
                    doyouwanttodeletepaymentmethod: 'Do You Sure You Want To Delete This Payment method?',
                    deletepayment: 'Delete Payment Method',
                    youhavenotaddedanypaymentmethodsyet: 'No Payment Methods Have Been Added Yet',
                    checked: 'Checked',
                    notchecked: 'Not Checked',
                    chosenpaymentmethods: 'Chosen Payment Methods',
                    emailsettingscontractinformation:
                        'Selecting “Yes” means that your customers will not receive emailed confirmations of completed, refunded, or voided transactions, even if the platform has enabled them globally. Customers may still receive confirmation emails from the provider. It’s useful to select “Yes” if you already send a transaction confirmation to your customers.',
                    usesandboxprovidercomment:
                        "If you checked Use provider sandbox, all payments will be sent to this provider's sandbox environment,and if not checked, payments will be sent to provider Live environment.",
                    choosecountry: 'Choose Country',
                    // Fawry //
                    fawrydescription: `Make sure you have your Fawry Account Manager to set the following url as your payment notification URL (Callback URL):
                          <br>https://moneyhash.io/providers/fawry/webhook/`,
                    providedbyfawrypayteamduringtheaccountsetup: 'Provided by fawry pay team during the account setup',
                    // Paymob //
                    paymobinstructions: `Make sure you add the following urls in all Payment Integrations in your Paymob Accept dashboard:
                            <br>https://moneyhash.io/providers/paymob/webhook/processed/ (in Transactionprocessed callback)
                            <br>https://moneyhash.io/providers/paymob/webhook/response/ (in Transaction response callback)`,
                    mobilewalletinputcomment: 'You must add +2 to phone number; ex: +20011111111',
                    // Cow pay //
                    cowpaycodeinputcomment: 'Provided by Cowpay team during the account setup.',
                    cowpaytokencomment: 'Authentication token for Staging or Production based on use_provider_sandbox field',
                    cowpaycheckboxcomment: "If checked, all payments will be sent to this provider's sandbox environment,and if not checked, payments will be sent to provider live environment.",
                    // Pay tabs //
                    paytabsinputcomment: 'Obtained From The Provider Dashboard',

                    // Analytics
                    connect: 'Connect',
                    connectto: 'Connect To',
                    disconnect: 'Disconnect',
                    id: 'ID',
                    id2: 'ID',
                    allowsyoutotrackwebsiteperformanceandcollectvisitorinsights: 'Allows you to track website performance and collect visitor insights.',
                    allowsyoutomeasureyouradvertisingROIandtrackyoursocialnetworkingsitesandapplications:
                        'Allows you to measure your advertising ROI and track your social networking sites and applications.',
                    allowsyoutomeasuretheeffectivenessofyouradvertising: 'Allows you to measure the effectiveness of your advertising.',

                    // General Settings
                    userinformation: 'User Information',
                    changeemail: 'Change Email',
                    subscriptiondetails: 'Subscription Details',
                    currentemail: 'Current Email',
                    newemail: 'New Email',
                    oldpassword: 'Old Password',
                    newpassword: 'New Password',
                    errorinchangingemail: 'Error In Changing Email',
                    errorinchangingpassword: 'Error In Changing Password',
                    serveremailchangeerror: 'Error, error changing your email, please contact tabexshops team.',
                    serverpasswordchangeerror: 'Error, error changing your password, please contact tabexshops team.',
                    updateuserinformation: 'Update User Information',
                    errorinaddinginformation: 'Error In Adding Information',
                    errorineditinginformation: 'Error In Editing Information',
                    dataupadated: 'Data Upadated Successfully',
                    servererrorinaddinginformation: 'Error, error adding information, please contact tabexshops team.',
                    servererrorineditinginformation: 'Error, error editing information, please contact tabexshops team.',
                    sendverificationcode: 'Send Verification Code',
                    verificationcoderequested: 'Verification Code Requested',
                    codeverified: 'Code Verified',
                    youremailhaschangesuccessfully: 'Your Email Has Changed Successfully',
                    pleaseinserttheverificationcode: 'Please Insert The Verification Code',
                    verificationcode: 'Verification Code',
                    packagename: 'Package Name',
                    duration: 'Duration',
                    fees: 'Fees',
                    annualfees: 'Annual Fees',
                    maximuminstitutes: 'Maximum Institutes',
                    mobileapplication: 'Mobile Application',
                    emailalreadyexists: 'Email Already Exists',
                    changepassword: 'Change Password',
                    passwordalreadyexists: 'Password Already Exists',
                    passwordchangedsuccessfully: 'Password Changed Successfully',
                    oldpasswordisincorrect: 'Old Password Is Incorrect',

                    // Uploder
                    chosenfiles: 'Chosen files',
                    uploadfiles: 'Upload Files',
                    uploaderdropdowntext: 'Drag & Drop Files or Click To Browse',

                    // Mobile Application
                    notificationslogs: 'Notifications Logs',
                    mobileapplicationusers: 'Mobile Application Users',
                    loggedinusers: 'Logged-in Users',
                    alldevices: 'All Devices',
                    notificationid: 'Notification ID',
                    banned: 'Banned',
                    loggedin: 'Logged-In',
                    lastseen: 'last seen',
                    loggedindevicetype: 'Logged-in Device Type',
                    mob_deviceid: 'Mobile Device ID',
                    notificationallowed: 'Notifications Allowed',
                    sendnotification: 'Send Notification',
                    banemail: 'Ban E-mail',
                    notificationcontent: 'Notification Content',
                    notificationtitle: 'Notification Title',
                    sendnotificationto: 'Send Notification To',
                    notloggedin: 'Not Logged-in',
                    notallowed: 'Not Allowed',
                    allowed: 'Allowed',
                    website: 'Website',

                    // Users
                    adduser: 'Add New User',
                    edituser: 'Edit User Information',
                    deleteuser: 'Delete User',
                    youhavenousersyet: 'You Have No Users Yet',

                    // SMS SERVICE
                    smsserviceinformation: 'SMS Service Information',
                    senderid: 'Sender ID',
                    addsenderid: 'Add Sender ID',
                    thissenderid: 'this sender ID',
                    deletesenderid: 'Delete Sender ID',
                    sendername: 'Sender Name',
                    senderstatus: 'Sender Status',
                    taxpaper: 'Tax Paper',
                    instpaper: 'Inst Paper',
                    balance: 'Balance',
                    requestbalance: 'Request Balance',
                    numberofmsgs: 'Number Of Messages',
                    smslogs: 'SMS Logs',
                    logid: 'Log ID',
                    smsstatus: 'SMS Status',
                    reason: 'Reason',
                    smscontent: 'SMS Content',
                    requestedbalance: 'Requested Balance',
                    noofmsgs: 'Number Of Messages',
                    balancelogs: 'Balance Logs',
                    balancelogid: 'Balance Log ID',
                    nosmsrequested: 'Number Of SMS Requested',
                    addsernderid: 'Add Sender ID',
                    addbalance: 'Add Balance',
                    sendmessage: 'Send Message',
                    smsbody: 'SMS Body',
                    receiverphonenumber: 'Receiver Phone Number',

                    // Vendors
                    allvendors: 'All Vendors',
                    vendorname: 'Vendor Name',
                    location: 'Location',
                    addvendor: 'Add Vendor',
                    deletevendor: 'Delete Vendor',
                    editvendorinfo: 'Edit Vendor Information',
                    doyouwanttodeletethisvendor: 'Do You Want To Delete This Vendors?',

                    // Categories
                    nocollections: 'No Categories Found',
                    categories: 'Categories',
                    addcategory: 'Add Category',
                    deletecategory: 'Delete Category',
                    editcategory: 'Edit Category',
                    categoryname: 'Category Name',
                    collections: 'Collections',
                    visibilitystatus: 'Visibility Status',
                    visibilityinfilter: 'Visibility Status in Fiter',
                    categorylogo: 'Category Logo',
                    categoryinfo: 'Category Information',
                    categorydescription: 'Category Description',
                    categorystatus: 'Category Status',
                    visibleinfilter: 'Visible in Filter',
                    visibletocustomers: 'Visible to Customers',
                    categorycollections: 'Category Collections',
                    chosencollections: 'Chosen Collections',

                    // Notifications
                    servererrorupdatevendors: 'Error in Updating Vendors. Please Contact Tabex Team',
                    vendorsupdatesuccess: 'Vendors updated successfully',
                    servererrorupdatesecuritygp: 'Error In Updating Security Groups. Please Context Tabex Team',
                    securitygpupdatedsuccess: 'Security Groups Updated Successfully',
                    // Products Notifications
                    optionsupdatessuccess: 'Options Updated Successfully',
                    servererrorupdateoptions: 'Error in Updating Options. Please contact Tabex Team',
                    valuealreadyexists: 'Value already exists',
                    pleasewritevaluename: 'Please Write Value Name',
                    pleasewritevalues: 'Please Write Values',
                    pleasewriteoptionname: 'Please Write Option Name',
                    productaddedsuccess: 'Product is added successfully',
                    servererroraddingproduct: 'Error in Adding Product. Please contact Tabex Team',
                    optionsupdatedsuccess: 'Product Options updated successfully',
                    servererroroptionsupdated: 'Error. Please Contact Tabex Team ',
                    deleteproductsuccess: 'Product is Deleted',
                    servererrordeleteproduct: 'Error in Deleting Product. Please contact Tabex Team',
                    errorupdateproduct: 'Error In Updating Product Information',
                    // Images
                    imageuploadedsuccess: 'Image(s) is/are uploaded successfully',
                    imagedeletedsuccess: 'Image is deleted Successfully',
                    servererroruploadingimage: 'Error in Uploading Images(s). Please Contact Tabex Team',
                    servererrordeleteimage: 'Error in Deleting Image. Please Contact Tabex Team',
                    // Product Images
                    galleryimagedeletedsuccess: 'Image is deleted Successfully',
                    servererrordeletegalleryimage: 'Error in Deleting Image. Please Contact Tabex Team',
                    defaultimageupdatedsuccess: 'Default Image is Updated',
                    servererrorupdatedefaultimage: 'Error in Updating Default Image. Please Contact Tabex Team',
                    // Quantity
                    erroraddingquantity: 'Error in Adding Quantity. Please Contact Tabex Team',
                    // Prod Info
                    prodinfoupdatessuccess: 'Product Information is Updated Successfully',
                    servererrorupdateprodinfo: 'Error in Updating Product Information. Please Contact Tabex Team',
                    // Choose Collection
                    pleasechoosecollection: 'Please Choose Collection',
                    servererrorchangeprodoptionvalues: 'Error in Changing Product Options Values. Please Contact Tabex Team',
                    // Prod Avail
                    productavailupdatesuccess: 'Product Availability is Updated Successfully',
                    servererrorupdateprodavail: 'Error in Changing Product Availability. Please Contact Tabex Team',
                    // Inventory
                    inventoryupdateduccess: 'Inventory is Updated Successfully',
                    servererrorupdateinventory: 'Error in Updating Inventory. Please Contact Tabex Team',
                    // Variants
                    statuschangessuccess: 'Status is Changed Successfully',
                    servererrorchangevariantsstatus: 'Error in Changing Status Information. Please Contact Tabex Team',
                    variantimageupdated: 'Variant Image is updated successfully',
                    variantupdatedsuccess: 'Variant is Updated successfully',
                    servererrorupdatevariant: 'Error in Updated Variant. Please Contact Tabex Team',
                    variantaddedsuccess: 'Variant is added',
                    servererroraddvariant: 'Error in Adding Variant. Please Contact Tabex Team',
                    variantdeletedsuccess: 'Variant is deleted',
                    servererrordeletevariant: 'Error in Deleting Variant. Please Contact Tabex Team',
                    // Pricing
                    defaultpricingupdatedsuccess: 'Default Pricing is Updated Successfully',
                    servererrorupdatedefaultpricing: 'Error in Updating Default Pricing. Please Contact Tabex Team',
                    // servererror: 'Error In Editing Translation, Please Contact Tabexshops Team',
                    policiessavedsuccessfully: 'Data Saved Successfully',
                    servererrorsavepolicy: 'Error in Saving Data. Please Contact Tabex Team',
                    translationsuccessfullysaved: 'Translation Successfully Saved',

                    // Orders Notifications
                    shippingzonefound: 'Shipping Zone Found',
                    promocoderemoved: 'Promo Code is Removed',
                    servererrorcheckingzones: 'Error In Checking Zones. Please Contact Tabex Team',
                    promocodeapplied: 'Promo Code Applied',
                    servererrorapplyingpromocode: 'Error In Applying Promo Codes. Please Contact Tabex Team',
                    pleasewriteyourpromocode: 'Please Write Your Promo Code',
                    orderisaddedsuccess: 'Order is added successfully',
                    servererroraddingorder: 'Error in Adding Order. Please Contact Tabex Team',
                    pleaseaddproducts: 'Please Add Products First',
                    pleasechoosefulfillmentstatus: 'Please Choose Fulfillment Status',
                    pleasechoosepaidstatus: 'Please Choose Paid Status',
                    pleasechooseorderstatus: 'Please Choose Order Status',
                    itemisaddedsuccess: 'Item is added successfully',
                    itemisdeletedsuccess: 'Item is deleted successfully',
                    orderisdeletedsuccess: 'Order is deleted successfully',
                    statusiseditedsuccess: 'Order Status is edited successfully',
                    servererrordeleteorder: 'Error in deleting order. Please Contact Tabex Team',
                    servererrorremoveitem: 'Error in removing item. Please Contact Tabex Team',
                    quantityisadded: 'Quantity is added',
                    completestepstoplaceorder: 'Complete steps to place the order',
                    servererroreditstatus: 'Error in editing order status. Please Contact Tabex Team',
                    pleaseaddquantity: 'Please add quantity',
                    servererrorsearchorders: 'Error In Searching For Order. Please Contact Tabex Team',
                    servererrorupdatingorders: 'Error In Updating Order. Please Contact Tabex Team',
                    orderupdatedsuccess: 'Order is Updated Successfully',
                    servererrorsearchvendors: 'Error In Searching For Vendors. Please Contact Tabex Team',
                    choosecountrystatecity: 'Please Choose Country, State & City',
                    doyouwanttodeleteorder: 'Do You Want To Delete This Order?',
                    doyouwanttodeleteorderandreturnitems: 'Do You Want To Delete This Order and return item quantity?',
                    archive: 'Archive',
                    servererroreditingorderstatus: 'Error In Editing Order Status. Please Contact Tabex Team',
                    orderstatusupdatedsuccess: 'Order Status is Updated Successfully',
                    // Collections Notifications
                    collectionaddedsuccessfully: 'Collection is added successfully',
                    collectiondeletedsuccessfully: 'Collection is deleted successfully',
                    collectioneditedsuccessfully: 'Collection is edited successfully',
                    collectionlogoupdatesucess: 'Collection logo is updated successfully',
                    servererroraddingcollection: 'Error In Adding Collection. Please Contact Tabex Team',
                    servererroreditingcollection: 'Error in Editing Collection. Please Contact Tabex Team',
                    servererrorupdatecollectionlogo: 'Error in Updating Collection Logo. Please Contact Tabex Team',
                    itemisdeletedfromcollection: 'Product is removed from collection',
                    itemisaddedtocollection: 'Product is added to collection',
                    // Customers Notifications
                    addcustomerservererror: 'Error in Adding Customer. Please Contact Tabex Team.',
                    editcustomerservererror: 'Error in Editing Customer. Please Contact Tabex Team.',
                    deletecustomerservererror: 'Error in Deleting Customer. Please Contact Tabex Team.',
                    customerhasbeensuccessfullyadded: 'Customer is added successfully ',
                    customerhasbeensuccessfullyedited: 'Customer information is edited Successfully ',
                    customerhasbeensuccessfullydeleted: 'Customer is deleted Successfully',
                    pleaseaddname: 'Please Add Name',
                    pleaseselectbranch: 'Please Select Branch',
                    // Shipping Notifications
                    servererroraddingzone: 'Error in Adding Zone. Please Contact Tabex Team',
                    servererroreditingzone: 'Error in Editing Zone. Please Contact Tabex Team',
                    servererrordeletingzone: 'Error in Deleting Zone. Please Contact Tabex Team',
                    zoneeditedsuccess: 'Zone is edited successfully',
                    zoneaddedsuccess: 'Zone is add successfully',
                    zonedeletedsuccess: 'Zone is deleted successfully',
                    allstatesselected: 'All Stated are selected',
                    allstatesunselected: 'All Stated are removed',
                    // Branches Notifications
                    brancheditedsuccess: 'Branch is edited successfully',
                    branchaddessuccess: 'Branch is added successfully',
                    branchdeleted: 'Branch is deleted successfully',
                    errorinaddingbranchserver: 'Error in Adding Branch. Please Contact Tabex Team',
                    erroreditingbranchserver: 'Error in Editing Branch. Please Contact Tabex Team',
                    errordeletingbranchserver: 'Error in Deleting Branch. Please Contact Tabex Team',
                    // Institute Settings Notifications
                    pleasecomleteyourcontactinformation: 'Please complete your contact information',
                    contactinfoupdatedsuccess: 'Contact Information is updated successfully',
                    servererrorcontactinfo: 'Error in updating contact information. Please contact Tabex Team',
                    storeinfoupdatedsuccess: 'Store Information is updated successfully',
                    servererrorstoreinfo: 'Error in updating store information. Please contact Tabex Team',
                    logoaddedsuccess: 'Logo is updated successfully.',
                    erroruploadingfavicon: 'Error in uploading favicon. Please contact Tabex Team',
                    faviconupdatedsuccess: 'Favicon in updated successfully',
                    erroruploadinglogo: 'Error in uploading store logo. Please contact Tabex Team',
                    pleaseuploadimage: 'Please upload an image first',
                    pleasecompletecompletestoreinfo: 'Please complete your contact information',
                    // Signup data
                    infoaddedsuccess: 'Added successfully',
                    infoeditedsuccess: 'Edited successfully',
                    infoeletedsuccess: 'Deleted successfully',
                    // Payment Notifications
                    paymentmethodadded: 'Payment Method is added successfully',
                    paymentmethodenabled: 'Payment Method is now enabled',
                    paymentmethoddisabled: 'Payment Method is now Disabled',
                    servererroraddpaymentmethod: 'Error in adding payment method. Please contact Tabex Team',
                    servererrordeletepaymentmethod: 'Error in editing payment method. Please contact Tabex Team',
                    servererrorchangeactivationpaymentmethod: 'Error in chnging activation status. Please contact Tabex Team',
                    // Coupons Notifications
                    serveraddpromocodeerror: 'Error in Adding coupon, Please Contact Tabex Team.',
                    servereditpromocodeerror: 'Error in Editing coupon, Please Contact Tabex Team.',
                    serverdeletepromocodeerror: 'Error in Deleting coupon, Please Contact Tabex Team.',
                    couponaddedsuccess: 'Coupon is added successfully',
                    couponeditedsuccess: 'Coupon is edited successfully',
                    coupondeletedsucess: 'Coupon is deleted',

                    serveraddtableerror: 'Error in Adding table, Please Contact Tabex Team.',
                    serveredittableerror: 'Error in Editing table, Please Contact Tabex Team.',
                    serverdeletetableerror: 'Error in Deleting table, Please Contact Tabex Team.',
                    tableaddedsuccess: 'Table is added successfully',
                    tableeditedsuccess: 'Table is edited successfully',
                    tabledeletedsucess: 'Table is deleted',
                    // Users Notifications
                    servererroraddinguser: 'Error in Adding User. Please Contact Tabex Team',
                    servererroreditinguser: 'Error in Editing User. Please Contact Tabex Team',
                    servererrordeletinguser: 'Error in Deleting User. Please Contact Tabex Team',
                    usereditedsuccess: 'User is edited successfully',
                    useraddedsuccess: 'User is add successfully',
                    userdeletedsuccess: 'User is deleted successfully',
                    // SMS Service Notifications
                    senderidaddedsuccess: 'Sender ID is added successfully',
                    senderiddeletedsuccess: 'Sender ID is deleted successfully',
                    servererroraddsenderid: 'Error in Adding Sender ID. Please Contact Tabex Team',
                    servererrordeletesenderid: 'Error in Deleting Sender ID. Please Contact Tabex Team',
                    servererrorsendsms: 'Error in Sending Message. Please Contact Tabex Team',
                    sendsmssentsuccess: 'SMS Has Been Sent Successfully',
                    // Mobile Application Notifications
                    notificationsentsucceess: 'Notification is sent',
                    servererrorsendnotification: 'Error in Sending Notification. Please Contact Tabex Team',
                    allusersselected: 'All Customers are Selected',
                    // Couriers Notifications
                    servererrorupdatecouriers: 'Error in Updating Couriers. Please Contact Tabex Team',
                    couriersupdatedsuccessfully: 'Couriers are updated',
                    zonealreadyexists: 'Zone is already exists',
                    deletecourierconfirmation: 'Do You Want To Delete This Courier?',
                    // Categories Notifications
                    categoriesupdatedsuccess: ' Categories are updated successfuly',
                    servererrorerrorupdatingcategories: 'Error in Updating Categories. Please Contact Tabex Team',
                    categoryaddedsuccess: 'Category is added successfuly',
                    servererrorerroraddingcategory: 'Error in adding categories. Please contact Tabex Team',
                    errordeletecategory: 'Error in deleting categories. Please contact Tabex Team',
                    monthly: 'Monthly',
                    open: 'Open',
                    // Paid and fulfilment Status
                    itemfulfillmentstatus: 'Item Fulfilment Status',
                    fulfillmentstatus: 'Fulfilment status',
                    paidstatus: 'Paid',
                    paid: 'Paid',
                    unpaid: 'Unpaid',
                    isuserblocked: 'Block user',
                    userblocked: 'User Blocked',
                    userunblocked: 'User Unblocked',
                    canceled: 'Cancelled',
                    fulfilled: 'Fulfilled',
                    unfulfilled: 'Unfulfilled',
                    partiallyfulfilled: 'Partially Fulfilled',
                    enabled: 'Enabled',
                    disabled: 'Disabled',
                    // ===============================================================================================
                    subscribenow: 'ٍSubscribe Now',
                    month: 'Month(s)',
                    youdonthavesubscriptionsyet: 'you dont have subscriptions yet',
                    timestmap: 'Time Stamp',
                    paidwith: 'Paid With',
                    finalprice: 'Final Price',
                    subsriptionstarts: 'Subsription Starts',
                    offerprice: 'Offer Price',
                    packageprice: 'Package Price',
                    lastsubscriptiondate: 'Last Subscription Date',
                    itemsandreservations: 'Items & Reservations',
                    package: 'Package',
                    system: 'System',
                    reservationsettings: 'Reservation Settings',
                    sat: 'Sat',
                    sun: 'Sun',
                    mon: 'Mon',
                    tues: 'Tues',
                    wednes: 'Wednes',
                    thurs: 'Thurs',
                    fri: 'Fri',
                    socialmedia: 'Social Media',
                    currencies: 'Currencies',
                    addcurrency: 'Add Currency',
                    hide: 'Hide',
                    show: 'Show',
                    editcurrency: 'Edit Currency',
                    deletecurrency: 'Delete Currency',
                    webapp: 'Dashboard',
                    vendors: 'Vendors',
                    subscriptions: 'Subscriptions',
                    institueactivitystatus: 'Institue Activity Status',
                    reservations: 'Reservations',
                    reservationtype: 'Reservation Type',
                    minutes: 'Minutes',
                    days: 'Days',
                    startson: 'Starts on',
                    endon: 'Ends on',
                    maxreservation: 'Maximum no. of reservations',
                    maxdays: 'Maximum no. of days',
                    maxresperday: 'Maximum reservations per day',
                    slotdifference: 'Slots Difference',
                    overridereservations: 'Override reservations',
                    yes: 'Yes',
                    no: 'No',
                    service: 'Service',
                    maxorderquantpercustomerperday: 'Max order quantity per customer in one day',
                    maxorderquantpercustomer: 'Max order quantity per customer',
                    productindex: 'Product index',
                    index: 'index',
                    clear: 'Clear',
                    totalorderedquantity: 'Total ordered quantity',
                    clearorderedquantity: 'Clear ordered quantity',
                    clearandkeepproductquantity: 'Clear ordered quantity and keep quantities',
                    productdeliverytext: 'Product delivery text',
                    productshippingfees: 'Product shipping fees',
                    websiteviews: 'Website Views',
                    mobappviews: 'App Views',
                    selectallcities: 'Select all cities',
                    removeallcities: 'Remove all cities',
                    totalcities: 'Total cities',
                    wallet: 'Wallet',
                    amount: 'Amount',
                    thisreceiptisrequiredforanexchangeorrefundtransaction: 'This receipt is required for an exchange or refund transaction',
                    thermalprint: 'thermal print',
                    productfeatures: 'Product Features',
                    featurename: 'Feature Name',
                    featureindex: 'Feature Index',
                    addfeature: 'Add Feature',
                    editfeature: 'Edit Feature',
                    featuretype: 'Feature Type',
                    checkbox: 'Checkbox',
                    number: 'Number',
                    dynamicforms: 'Dynamic Forms',
                    addnewform: 'Add New Form',
                    answers: 'Answers',
                    submittedat: 'Submitted at',

                    addform: 'Add Form',
                    editform: 'Edit Form',
                    formtitleen: 'Form title En',
                    formtitlear: 'Form title Ar',
                    yourmobileapp: 'Your mobile application',
                    website: 'website',
                    inoneplace: 'in one place',
                    slogan: 'Create it with the creativity of your own',
                    donthaveaccount: "Don't have an account?",
                    signup: 'Sign up',

                    tickets: 'Tickets',
                    createticket: 'Create Ticket',
                    createemergencyticket: 'Create Emergency Ticket',
                    addanattachment: 'Add an attachment',
                    Thefileattachmustbeafile: 'The file attach must be a file of type: jpeg, png, jpg, gif, svg, mp4, pdf.',
                    reply: 'Reply',
                    describeyourissueasclearlyasyoucan: 'Describe your issue as clearly as you can',
                    createanewemergencysupportticket: 'Create a new emergency support ticket',
                    createanewsupportticket: 'Create a new support ticket',
                    tickettype: 'Ticket type',
                    dashboardorders: 'Dashboard - Orders',
                    dashboardproducts: 'Dashboard - Products',
                    dashboardgeneral: 'Dashboard - General',
                    websitetemplate: 'Website - Template',
                    websitefunctions: 'Website - Functions',
                    mobileapplicationtemplate: 'Mobile Application - Template',
                    mobileapplicationfunctions: 'Mobile Application - Functions',
                    other: 'Other',

                    subject: 'Subject',
                    ticketsubject: 'Ticket subject',
                    pleasedescribeyourissue: 'Please describe your issue',

                    emergency: 'Emergency',
                    pending: 'Pending',
                    inprogress: 'In progress',
                    resolved: 'Resolved',
                    noticketsyet: 'You have no tickets yet',

                    tabexsupport: 'Tabex Support',

                    workingdays: 'Working days',
                    workinghours: 'Working Hours',
                    sundaytothursday: 'Sunday to Thursday',
                    fromtenamtosixpm: '10:30 AM to 6 PM',

                    productextras: 'Product Extras',

                    packagelist: 'Package List',
                    priceperpiece: 'Price per piece',

                    virtualtryon: 'Virtual Try-On',
                    imagetype: 'Image Type',
                    tryonplace: 'Try-On place',
                    searchbystatus: 'Search By Status',
                },
                ar: {
                    close: 'غلق',

                    support_assistance_rating: 'كيف تقيم المساعدة التي تلقيتها؟',
                    website_design_satisfaction: 'ما مدى رضاك عن تصميم موقع الويب الخاص بك؟',
                    overall_website_satisfaction: 'بشكل عام، ما مدى رضاك عن موقع تابكس الإلكتروني؟',
                    dashboard_usability: 'ما مدى سهولة استخدامك للوحة التحكم؟',
                    mobile_application_selectbox: 'هل لديك تطبيق موبايل؟',
                    mobile_application_design_satisfaction: 'ما مدى رضاك عن تصميم تطبيق الموبايل الخاص بك؟',
                    overall_mobile_application_satisfaction: 'بشكل عام، ما مدى رضاك عن تطبيق تابكس للموبايل؟',
                    surveytitle: 'شاركونا رأيكم:',
                    surveytitle2: 'استبيان رضا العملاء',
                    support: 'دعم',
                    tables: 'طاولات',
                    table: 'طاولة',
                    downloadqr: 'تحميل QR',
                    addtable: 'أضف طاولة',
                    tablename: 'اسم الطاولة',
                    youhavenotablesyet: 'ليس لديك طاولات بعد',
                    doyouwanttodeletethistable: 'هل تريد حذف هذه الطاولة؟',
                    edittable: 'تعديل الطاولة',
                    deletetable: 'حذف الطاولة',
                    donthaveaccount: 'ليس لديك حساب؟',
                    signup: 'إشترك',

                    yourmobileapp: 'تطبيق هاتفك',
                    website: 'موقعك الإلكترونى',
                    inoneplace: 'فى مكان واحد',
                    slogan: 'قم بإنشاؤه بإبداعك الخاص',

                    shortdescription: 'الوصف القصير',
                    totaltobeused: 'العدد الذي سيتم استخدامه',
                    totaltobeusedbyeachcustomer: 'العدد الذي سيستخدمه كل عميل',
                    usedby: 'يستعمل من قبل',
                    customerandguests: 'العملاء الزائرين',
                    customersonly: 'العملاء فقط',
                    percentage: 'نسبة مئوية',
                    value: 'قيمة',
                    sendtoallcustomers: 'أرسل إلى جميع العملاء',
                    sendtounregisteredusers: 'أرسل إلى المستخدمين غير المسجلين',
                    sendpushnotificationto: 'إرسال إشعار إلى',
                    notificationcancelled: 'تم إلغاء الإخطار',
                    pleasewritetitlesndbody: 'الرجاء كتابة العنوان والجسم',
                    body: 'محتوى',
                    sendpushnotification: 'إرسال إشعار',
                    walletlogs: 'سجلات المحفظة',
                    walletbalance: 'رصيد المحفظة',
                    walletpoints: 'نقاط المحفظة',
                    subtract: 'طرح',
                    modifywallet: 'تعديل المحفظة',
                    pointstobe: 'النقاط لتكون',
                    added: 'مضافة',
                    subtracted: 'مطروحة',
                    issubscriptionactive: 'هل الاشتراك نشط',
                    subscriptionname: 'اسم الاشتراك',
                    subscriptionstarts: 'يبدأ الاشتراك في',
                    subscriptionends: 'الاشتراك ينتهي في',
                    history: 'سجل',
                    starts: 'يبدأ',
                    expires: 'تنتهي',
                    subscribecustomer: 'اشتراك العميل',
                    choose: 'اختر',
                    pushnotification: 'إرسال الإخطار',
                    removeall: 'حذف الكل',
                    addall: 'إضافة الجميع',
                    youhavesuccessfullysubscribed: 'لقد تم اشتراكك بنجاح',
                    checksubscriptions: 'تحقق من الاشتراكات',
                    failed: 'فشل',
                    upgradingsubscriptions: 'تحديث الاشتراكات',
                    packagefeesmonthly: 'رسوم الباقة شهريا',
                    maxcustomers: 'أقصى عدد من العملاء',
                    nodata: 'لايوجد بيانات',
                    novendor: 'لا بائع',
                    productextrafields: 'حقول الإدخال الإضافية للمنتج',
                    subtitle: 'العنوان الفرعي',
                    addfield: 'أضف حقل الإدخال',
                    duplicate: 'مكررة',
                    areyousureyouwanttodeletethisfield: 'هل أنت متأكد أنك تريد حذف هذا الحقل',
                    showproduct: 'عرض المنتج',
                    overwritevariants: 'الكتابة فوق المتغيرات',
                    areyousureyouwanttoclear: 'هل أنت متأكد أنك تريد المسح؟',
                    areyousureyouwanttoclearandkeepquantity: 'هل أنت متأكد أنك تريد مسح الكمية والاحتفاظ بها؟',
                    setandclear: 'تعيين و مسح',
                    set: 'تعيين',
                    productaffiliatepercentageprofit: 'المنتج التابع نسبة الربح٪',
                    productcashbackvalue: 'قيمة الاسترداد النقدي للمنتج',
                    issubscriptionrequired: 'هل الاشتراك مطلوب',
                    is_max_product_ordered_related_tosubscription_date: 'هل الطلب الأقصى للمنتج مرتبط بتاريخ الاشتراك',

                    nosubscription: 'لا اشتراك',
                    subscriptionrequired: 'الاشتراك مطلوب',
                    forcetocheckout: 'إجبار على الخروج',
                    donotforce: 'لا تجبر',
                    force: 'إجبار',
                    producttobesold: 'المنتج المراد بيعه',
                    nottobesold: 'لا تباع',
                    tobesold: 'تباع',
                    productcanquantitychange: 'يمكن تغيير كمية المنتج',
                    ifonlyproductremoveallshippingfees: 'إذا كان المنتج فقط إزالة جميع رسوم الشحن',
                    setquantity: 'حدد الكمية',
                    vendorquantity: 'كمية البائع',
                    orderedlogs: 'سجلات الطلبات',
                    valueindex: 'مؤشر القيمة',
                    quantityconditions: 'شروط الكمية',
                    pleasefillthemissingfields: 'يرجى ملء الحقول المفقودة',
                    reviews: 'المراجعات',
                    importproductvariants: 'استيراد متغيرات المنتج',
                    autogeneratevariants: 'إنشاء متغيرات تلقائيًا',
                    notauthorizedtoviewproducts: 'غير مصرح لعرض المنتجات',
                    or: 'او',
                    generatevariants: 'توليد المتغيرات',
                    showvariant: 'عرض المتغير',
                    wecouldntfindyourproduct: 'لم نتمكن من العثور على المنتج الخاص بك',
                    areyousureyouwanttouploadimages: 'هل أنت متأكد أنك تريد تحميل الصور؟',
                    imagesuploadedsuccessfully: 'تم تحميل الصور بنجاح',
                    pleasechooseimages: 'الرجاء اختيار الصور',
                    maxMBperupload: '40 ميغا بايت كحد أقصى لكل تحميل.',
                    upload: 'رفع',
                    webandmob: 'ويب سايت و تطبيق الهاتف',

                    images: 'صور',
                    image: 'صورة',
                    maxMBperimage: 'الحد الأقصى: 3 ميجا بايت لكل صورة',
                    totalimagessizeperupload: 'إجمالي حجم الصور لكل تحميل',
                    imagenamestructure: 'هيكل اسم الصورة',
                    imagealreadyexists: 'الصورة موجودة بالفعل',
                    import: 'استورد',
                    imagestotalsize: 'الحجم الإجمالي للصور',
                    notauthorizedtoviewcollections: 'غير مصرح للنظر في المجموعات',
                    pleasefixtheerrorsbelowinordertouploadyourimages: 'يرجى تصحيح الأخطاء أدناه لتحميل صورك',
                    importparentcollections: 'استيراد المجموعات الأصل',
                    importcategories: 'استيراد الفئات',
                    addaffiliate: 'إضافة منتسب',
                    choosesecuritygroup: 'اختر مجموعة الأمان',
                    nocompany: 'لا توجد شركة',
                    pickupaddress: 'عنوان الاستلام',
                    pickupcity: 'مدينة الاستلام',
                    copylink: 'نسخ عنوان الرابط',
                    copy: 'نسخ',
                    cancustomerdeleteorder: 'يمكن للعميل حذف الطلب',
                    enableguestorders: 'قم بتمكين طلبات الضيف',
                    enableproductdailylimit: 'تفعيل الحد اليومي للمنتج',
                    hideoutofstockproducts: 'إخفاء المنتجات التي نفذت من المخزون',
                    automaticsendorderstocourier: 'إرسال الطلبات تلقائيًا إلى المندوبين',
                    minordervalue: 'قيمة الحد الأدنى للطلب',
                    maxordervalue: 'قيمة الحد الأقصى',
                    contactphonenumber: 'رقم هاتف للاتصال',
                    subscriptioncollections: 'مجموعات الاشتراك',

                    customerstatus: 'حالة العميل',
                    customeractive: 'نشط',
                    customerdeleted: 'تم الحذف',
                    fname: 'الاسم الاول',
                    lname: 'الاسم الاخير',

                    confirmorder: 'إتمام الطلب',
                    totalcities: ' عدد المدن',
                    removeallcities: 'حذف جميع المدن',
                    selectallcities: 'اضافة جميع المدن',
                    cart: 'عربة التسوق',
                    clearcart: 'تفريغ عربة التسوق',
                    addtocart: 'اضف لعربة التسوق',
                    pleasechoosevariant: 'من فضلك اختر متغير',
                    pleasechoosequantity: 'من فضلك اختر الكمية',
                    productdeliverytext: 'توصيل المنتج',
                    productshippingfees: 'سعر توصيل المنتج',

                    totalorderedquantity: 'اجمالي الكمية المطلوبة',
                    clearorderedquantity: 'حذف الكميات المطلوبة',
                    clearandkeepproductquantity: '   حذف الكميات المطلوبة مع حفظ الكمية ',

                    clear: 'مسح',
                    yourreceipt: 'الفاتورة',
                    subscriptiontext1: 'لو عندك اى استفسارات، كلمنا اى وقت',
                    subscriptiontext2: 'لتفاصيل و الفرق بين الباقات، زور موقعنا على ',
                    annual: 'سنوى',
                    Quarterly: 'ربع سنوى',
                    Bronze: 'الخطة البرونزية',
                    Silver: 'الخطة الفضية',
                    Gold: 'الخطة الذهبية',
                    Platinum: 'الخطة البلاتينية',
                    year: 'سنوى',
                    month: 'شهر',
                    packagename: 'إسم الباقة',
                    packages: 'الباقات',
                    isuserblocked: 'حظر المستخدم',
                    userblocked: 'المستخدم المحظور',
                    userunblocked: 'المستخدم غير محظور',
                    //clara
                    removesale: 'إزالة الخصم',
                    removecollection: 'إزالة المجموعة',
                    removevendor: 'إزالة البائع',
                    chooseaction: 'اختر الإجراء',
                    maxorderquantpercustomerperday: 'اقصى كمية فى الطلب للعميل في اليوم ',
                    maxorderquantpercustomer: 'اقصى كمية فى الطلب للعميل   ',

                    errorinupdatinganalytics: 'خطأ في تحديث التحليلات',
                    analyticsupdatedsucessfully: 'تم تحديث التحليلات بنجاح',
                    thisparentcollectionalreadyexists: 'هذه المجموعة الرئسية موجودة بالفعل',
                    categoriesupdatedsuccessfuly: 'تم تحديث الفئات بنجاح',
                    logoedited: 'تم تعديل الشعار',
                    pleasefilltitleenglishfieldanddescriptioninenglish: 'يرجى ملء حقل العنوان باللغة الإنجليزية والوصف باللغة الإنجليزية',
                    thisproductalreadyexists: 'هذا المنتج موجود بالفعل',
                    collectionfilehasbeenimportedsuccessfully: 'تم رفع ملف المجموعة بنجاح',
                    errorinimporting: 'خطأ في الرفع',
                    pleasechoosefile: 'الرجاء اختيار ملف',
                    errorinsearchingcustomers: 'خطأ في البحث عن العملاء',
                    errorinloggingout: 'خطأ في تسجيل الخروج',
                    domainsettingsupdated: 'تم تحديث إعدادات المجال',
                    statusisaddedsuccessfully: 'تم إضافة الحالة بنجاح',
                    statusiseditedsuccessfully: 'تم تحديث الحالة بنجاح',
                    statusisdeletedsuccessfully: 'تم حذف الحالة بنجاح',
                    pleasewritefilltheoptionfields: 'الرجاء كتابة تعبئة حقول الخيار',
                    allusersunselected: 'لم يتم اختيار جميع المستخدمين',
                    areaoutofzone: 'منطقة خارج المنطقة',
                    pleaseilltheaddressinformation: 'الرجاء ملء معلومات العنوان',
                    thisareaisoutofyourzone: 'هذه المنطقة خارج منطقتك',
                    parentcollectionsupdatedsuccessfully: 'تم تحديث المجموعات الرئسية بنجاح',
                    errorinupdatingparentcollections: 'خطأ في تحديث المجموعات الرئسية',
                    thiscollectionalreadyexists: 'هذه المجموعة موجودة بالفعل',
                    addname: 'اضف اسما',
                    maintagassignedtothisproduct: 'تم تخصيص العلامة الرئيسية لهذا المنتج',
                    tagaddedtoproduct: 'تمت إضافة العلامة إلى المنتج',
                    pleasechoosetag: 'الرجاء اختيار علامة',
                    pleasechooseproductsfirst: 'الرجاء اختيار المنتجات أولاً',
                    productshasbeenupdated: 'تم تحديث المنتجات',
                    errorinupdatingproducts: 'خطأ في تحديث المنتجات',
                    pleaseselectproductsfirst: 'يرجى تحديد المنتجات أولاً',
                    productsfilehasbeenimportedsuccessfully: 'تم رفع ملف المنتجات بنجاح',
                    pleasechoosefileorvendor: 'الرجاء اختيار ملف أو بائع',
                    errorinupdatingproducttags: 'خطأ في تحديث علامات المنتج',
                    Productstagsupdated: 'تم تحديث علامات المنتجات',
                    pleasewritetagname: 'الرجاء كتابة اسم العلامة',
                    errorinupdatingproductoptions: 'خطأ في تحديث خيارات المنتج',
                    errorinchangingproductoptionvalues: 'خطأ في تغيير قيم خيارات المنتج',
                    optionvaluesupdated: 'تم تحديث قيم الخيارات',
                    pleaseaddsecuritygroupname: 'الرجاء إضافة اسم مجموعة الأمان',
                    errorinaddinginstitute: 'خطأ في إضافة المنشأه',
                    errorineditinginstitute: 'خطأ في تحديث المنشأه',
                    errorindeletinginstitute: 'خطأ في حذف المنشأه',
                    insituteisaddedsucessfully: 'تمت إضافة المنشأه بنجاح',
                    insituteiseditedsucessfully: 'تم تحديث المنشأه بنجاح',
                    insituteisdeltedsucessfully: 'تم حذف المنشأه بنجاح',

                    print: 'اطبع',
                    totalafterdiscount: 'المجموع بعد الخصم',
                    parentcollectionlogo: 'شعار المجموعة الرئيسية ',
                    parentcollectioninformation: 'معلومات المجموعة الرئيسية',
                    parentcollectionstatus: 'وضع المجموعة الرئيسية',
                    collectionsinparent: 'المجموعات في المجموعة الرئيسية',
                    deleteparentcollections: 'حذف المجموعة الرئيسية',

                    categoryinformation: 'معلومات الفئة',
                    parentcollectionname: 'اسم المجموعة الرئيسية',
                    parentcollectiondescription: 'وصف المجموعة الرئيسية',
                    nomoreproducts: 'لا مزيد من المنتجات',

                    nomoreordersfound: 'لم يتم العثور على المزيد من الطلبات',

                    addparentcollection: 'اضف المجموعة الرئيسية',
                    editparentcollection: 'تعديل المجموعة الرئيسية',
                    viewall: 'عرض الكل',
                    viewnostatus: 'عرض بدون حالة',

                    searchresults: 'نتائج البحث',

                    recentactivityforallorders: 'النشاط الأخير لجميع الطلبات',
                    connecttoasubdomain: 'اتصل بمجال فرعي',
                    connecttoanexternaldomain: ' الاتصال بمجال خارجي',
                    subdomain: 'مجال فرعي',
                    externaldomain: 'مجال خارجي',
                    updatedomainsettings: 'تحديث إعدادات المجال',
                    orderid: 'رقم التعريف الخاص بالطلب',

                    setvalues: 'قم بتعيين القيم',
                    assignoptionfirst: 'قم بتعيين الخيار أولاً',
                    stategov: 'محافظة/الولاية',

                    importcollections: 'إدخال المجموعات',
                    nameinenglish: 'الاسم باللغة الإنجليزية',
                    nameinarabic: 'الاسم بالعربي',
                    update: 'تحديث',
                    updatestatusto: 'تحديث الحالة إلى',
                    commentsbox: 'مربع التعليقات',
                    variant: 'متغير',
                    institutename: 'المنشأه اسم',
                    userid: 'رقم هوية المستخدم',
                    addinstitute: 'اضف المنشأه',
                    editinstitute: 'تعديل المنشأه',
                    tableisempty: 'الجدول فارغ',

                    tabexshops: 'متاجر Tabex',
                    // Side Nav & Page Titles
                    sidnav_subscription: ' الاشتراك',

                    sidenav_subscriptionscustomers: 'اشتراكات العميل',
                    sidenav_onlinegeneralpayments: 'المدفوعات العامة الاكترونية ',
                    sidenav_home: 'الصفحة الرئيسية',
                    sidenav_dashboard: 'الصفحة الرئيسية',
                    sidenav_stores: 'المتاجر',
                    sidenav_websites: 'المواقع',
                    sidenav_orders: 'الطلبات',
                    sidenav_orderstracking: 'تتبع الطلبات',
                    sidenav_abandonedcarts: 'عربات التسوق المهجورة',

                    sidenav_products: 'المنتجات',
                    sidenav_collections: 'المجموعات',
                    sidenav_customers: 'العملاء',
                    sidenav_promocodes: 'اكواد الخصم',
                    sidenav_pricing: 'الإشتراك',
                    sidenav_design: 'التصميم',
                    sidenav_websitetemplates: 'تصميم الموقع',
                    sidenav_mobileapptemplates: 'تصميم التطبيق',
                    sidenav_domain: 'المجال',
                    sidenav_analytics: 'التحليلات',
                    sidenav_policies: 'الشروط و الاحكام',
                    sidenav_generalsettings: 'الإعدادات العامه',
                    sidenav_onlinepayment: 'طرق الدفع ',
                    sidenav_shipping: 'الشحن',
                    sidenav_institutesettings: 'إعدادات المنشأه',
                    sidenav_branches: 'الفروع',
                    pagetitle_showorder: 'عرض الطلب',
                    sidenav_paymentmethods: 'طرق الدفع',
                    sidenav_addnewproduct: 'إضافة منتج جديد',
                    sidenav_mobileapplication: 'تطبيق الهاتف',
                    sidenav_affiliates: 'الشركات التابعة',
                    sidenav_users: 'المستخدمين',
                    sidenav_smsservice: 'خاصيه الرسائل',
                    sidenav_securitylayers: 'طبقات الحمايه',
                    sidenav_vendors: 'الموردين',
                    sidenav_couriers: 'المندوبين',
                    sidenav_reports: 'التقارير',
                    sidenav_integrations: 'توصيلات خارجية',

                    // Side Nav Titles
                    sidenav_dashboards: 'الرئيسية',
                    sidenav_management: 'الإدارة',
                    sidenav_onlinestore: 'المتجر الإلكترونى',
                    sidenav_settings: 'الإعدادات',

                    nosecutitylayersfound: 'لا توجد طبقات حماية',

                    // Generall
                    wallet: 'المحفظة',
                    ssn: 'رقم الهوية',
                    showinfilter: 'الظهور فى الفلتر',
                    chosentags: 'العلامات المختارة',
                    tableformat: 'شكل الجدول',
                    cities: 'المدن',
                    state: 'المحافظة',
                    nostatusfound: 'لا توجد حالات',
                    totalusers: 'عدد المستخدمين',
                    from: 'من',
                    to: 'إلى',
                    filter: 'فلترة',
                    showfilter: 'إظهار الفلتر',
                    searchvendors: 'البحث عن موردين',
                    searchcollections: 'البحث عن مجموعات',
                    salestatus: 'حالة الخصم',
                    hassale: 'يوجد خصم',
                    nosale: 'بدون خصم',
                    all: 'الكل',
                    details: 'التفاصيل',
                    products: 'المنتجات',
                    chooseoption: 'اختر',
                    totalorders: 'إجمالى عدد الطلبات',
                    totalorders2: 'إجمالى الطلبات',
                    totalsales: 'إجمالى المبيعات',
                    lowstockproducts: 'منتجات وصلت للحد الادنى',
                    outofzone: 'خارج نطاق الشحن',
                    actiontype: 'نوع الفعل',
                    clearfilters: 'حذف التصفية',
                    online: 'عبر الانترنت',
                    apply: 'تطبيق',
                    remove: 'حذف',
                    notes: 'الملاحظات',
                    ownernotes: 'ملاحظات الشركة',
                    domain: 'المجال',
                    source: 'المصدر',
                    byyou: 'بواسطتك',
                    customer: 'عميل',
                    guest: 'زائر',
                    default: 'الاساسى',
                    choose: 'إختر',
                    done: 'تم',
                    image: 'الصورة',
                    deleteall: 'حذف الكل',
                    disclaimer: 'ملحوظة',
                    updatelogo: 'تحديث الشعار',
                    expirydate: 'تاريخ الإنتهاء',
                    institutes: 'المنشأت',
                    createdon: 'تاريخ الإنشاء',
                    mobile: 'الهاتف',
                    country: 'البلد',
                    address: 'العنوان',
                    send: 'إرسال',
                    select: 'اختر',
                    password: 'كلمة السر',
                    note: 'ملاحظة',
                    selectall: 'إختيار الكل',
                    unselectall: 'حذف الكل',
                    title: 'العنوان',
                    sale: 'خصم',
                    price: 'السعر',
                    saleprice: 'سعر الخصم',
                    onsale: 'تخفيض',
                    offsale: 'لا يوجد تخفيض',
                    languages: 'اللغات',
                    emailto: 'إرسال الى',
                    paymentdetails: 'تفاصيل الدفع',
                    archive: 'ارشفه',
                    branch: 'الفرع',
                    subscription: 'اشتراك',
                    name: 'الإسم',
                    email: 'البريد الإلكترونى',
                    phonenumber: 'رقم التليفون',
                    edit: 'تعديل',
                    delete: 'حذف',
                    description: 'الشرح',
                    ifyespleaswrite: 'إذا كانت الإجابه نعم، من فضلك اكتب',
                    pleasewrite: 'من فضلك اكتب',
                    warning: 'تحذير',
                    search: 'البحث...',
                    total: 'الإجمالى',
                    date: 'التاريخ',
                    manage: 'إدارة',
                    egp: 'ج.م',
                    show: 'عرض',
                    notadded: 'لا يوجد',
                    back: 'رجوع',
                    save: 'حفظ',
                    submit: 'إرسال',
                    addtranslation: 'إضافة الترجمه',
                    removetranslation: 'إزالة الترجمة',
                    contactinformation: 'البيانات الشخصيه',
                    add: 'اضف',
                    arabictranslaion: 'ترجمة باللغة العربية',
                    englishtranslation: 'ترجمة باللغة الإنجليزية',
                    for: 'ل',
                    option: 'الإختيار',
                    options: 'الإختيارات',
                    done: 'تم',
                    hello: 'اهلا',
                    arabic: 'لغه عربيه',
                    english: 'لغه إنجليزيه',
                    livepreview: 'المعاينة الحية',
                    subscribe: 'الإشتراك',
                    logout: 'خروج',
                    language: 'اللغة',
                    allproducts: 'جميع المنتجات',
                    chosenproducts: 'المنتجات المختارة',
                    pleasecompletethemissingfields: 'من فضلك اكمل باقى البيانات',
                    product_s_: 'منتج(ات)',
                    company: 'الشركة',
                    open: 'مفتوح',

                    // Status
                    securitygroup: 'مجموعة الحماية',
                    status: 'الحالة',
                    active: 'نشط',
                    draft: 'على الهامش',
                    notactive: 'غير نشط',
                    enable: 'تفعيل',
                    disable: 'تعطيل',
                    required: 'إجبارى',
                    optional: 'إختيارى',
                    visible: 'مرئى',
                    hidden: 'مخفى',
                    pending: 'منتظر',
                    waitingforpickup: 'منتظر حتى الإستلام',
                    pickedup: 'تم اخذه',

                    inputfield: 'Input Field',
                    selectbox: 'خانة الاختيارات',

                    // Welcome Page
                    manageyourstores: 'إدارة متاجرك',
                    createnewstore: 'إنشاء متجر جديد',
                    youhavenotcreatedanystoresyet: 'لم يتم إنشاء متجر بعد.',
                    yourstores: 'المتاجر:',
                    storename: 'إسم المتجر',
                    freetrial: 'تجربة مجانية',
                    dashboardhome: 'الصفحه الرئيسية',
                    upgrade: 'تطوير',
                    expired: 'منتهي الصلاحية',
                    type: 'النوع',
                    chooseoption: 'إختر',
                    currency: 'العملة',
                    createstore: 'إضافة المتجر',
                    noinstitutesyet: 'لا توجد منشأه بعد',
                    createnewinst: 'إضافة منشأه جديده',

                    // Shipping
                    updateshippingandaddressdetails: 'تحديث العنوان و بيانات الشحن',
                    shipping: 'الشحن',
                    chosenzones: 'المناطق المختارة',
                    addzone: 'إضافة منطقة جديدة',
                    zonename: 'إسم المنطقة',
                    zonerateprice: 'سعر الشحن',
                    zoneratesaleprice: 'سعر الشحن فى الخصم',
                    countries: 'البلاد',
                    editzone: 'تعديل بيانات المنطقة',
                    shippingmethods: 'طرق الشحن',

                    deletezone: 'حذف المنطقة',
                    youhavenotaddedanyzonesyet: 'لم يضاف اى مناطق بعد',
                    zonestates: 'المناطق',
                    shippingprice: 'سعر الشحن',
                    shippingsaleprice: 'سعر الشحن فى الخصم',
                    selectedzones: 'المناطق المختارة',
                    governorate: 'المحافظة',
                    city: 'المدينة',

                    // Couriers
                    zoneid: 'الرمز التعريفى للمنطقة',
                    zonename: 'إسم المنطقه',
                    zonecities: 'المناطق',
                    allcouriers: 'المندوبون',
                    addcourier: 'إضافة مندوب جديد',
                    couriername: 'إسم المندوب',
                    zones: 'المناطق',
                    addnewcourier: 'إضافة مندوب جديد',
                    editcourier: 'تعديل بيانات المندوب',
                    deletecourier: 'حذف المندوب',
                    nocouriersyet: 'لا يوجد مندوبين',

                    // Branches
                    addbranch: 'إضافة فرع جديد',
                    editbranch: 'تعديل الفرع',
                    deletebranch: 'حذف الفرع',
                    nobranchesyet: 'لا توجد فروع',
                    pleaseaddbranchname: 'من فضلك اضف اسم الفرع',

                    // Home
                    golivenow: 'اطلق متجرك الان!',
                    golivedesc: 'لا تفوت يوم اخر بدون الوجود على المنصة الإلكترونية!',
                    stepstocomplete: 'الخطوات المتبقية',
                    recentactivity: 'النشاطات الاخيرة',
                    homecard_createyourstore: 'انشأ متجرك',
                    homecard_addproducts: 'إضافة منتجات',
                    homecard_addcollections: 'إضافة مجموعات',
                    homecard_customizewebsite: 'تخصيص مظهر الموقع الإلكترونى',
                    homecard_addshippingdetails: 'إضافة تفاصيل الشحن',
                    homecard_golive: 'اطلق المتجر',
                    subscribenow: 'إشترك الان',

                    // Home2
                    sales: 'المبيعات',
                    totalsalestoday: 'جميع المبيعات لليوم',
                    profit: 'المكسب',
                    ratioperday: 'النسبة في اليوم',
                    totalorderstoday: 'جميع الطلبات لليوم',
                    visits: 'الزيارات',
                    totalvisitsfortoday: 'جميع الزيارات لليوم',
                    recentorders: 'الطلبات الحالية',
                    quickactions: 'إجرائات سريعة',
                    actions: 'إجرائات',

                    addcollection: 'إضافة مجموعة جديدة',
                    editcollection: 'تعديل المجموعة',

                    // Products
                    tagname: 'إسم العلامة',
                    addtag: 'اضف علامة للمنتج',
                    edittag: 'تحديث العلامة',
                    chooseproducttags: 'إخنر علامة المنتج',
                    productoptionmanagement: 'إدارة متغيرات المنتج',
                    measurementunit: 'وحدة التخزين',
                    editproductinformation: 'تعديل بيانات المنتج',
                    productinformation: 'بيانات المنتج',
                    productgallery: 'صور المنتج',
                    inventorysettings: 'إعدادات المخزن',

                    stockandpricing: 'المخزون و التسعير',
                    stockandinventory: 'المخزون و المخزن',
                    producttags: 'علامات',
                    importproducts: 'إدخال منتجات',
                    productstags: 'علامات المنتجات',
                    productslogs: 'سجلات المنتجات',
                    thresholdquantity: 'الحد الأدنى من الكمية',
                    assignproductoptions: 'إضافة الإختيارات',
                    assign: 'إضافة',
                    deassign: 'حذف',
                    assignvalues: 'إضافة المتغيرات',
                    doyouwanttodeletethisproduct: 'هل نريد حذف هذا المنتج؟',
                    addproducts: 'إضافة منتجات',
                    productvariants: 'متغيرات المنتج',
                    showproductvariants: 'عرض الخيارات',
                    noproductsyet: 'لا يوجد منتجات',
                    editproduct: 'تعديل المنتج',
                    addproduct: 'إضافة المنتج',
                    deleteproduct: 'حذف المنتج',
                    addnewproduct: 'إضافة منتج جديد',
                    filterbyavailability: 'التصفية حسب المنتجات المتاحة',
                    filterby: 'التصفية حسب',
                    unlisted: 'غير مصنفة',
                    productname: 'إسم المنتج',
                    collection: 'المجموعة',
                    parentcollections: ' المجموعة الرئيسية',
                    inventorystatus: 'حالة المخزون',
                    limitedquantity: 'متوفر لفترة محدودة',
                    alwaysavailable: 'متوفر دائماً',
                    exportproductsasCSV: 'تصدير المنتجات على هيئة ملف CSV',
                    export: 'إصدار ',
                    exportproducts: 'إصدار المنتجات',
                    currentpage: 'الصفحة الحالية',
                    selectedproducts: 'المنتجات المُختارة',
                    filteredproducts: 'المنتجات المُفلترة',
                    productavailability: 'توافر ',
                    changeproductavailability: 'تغيير حالة توافر المنتج',
                    doyouwantchangeprodavailto: 'هل تريد تغيير حالة توافر المنتج ل ',
                    productinformation: 'تفاصيل المنتج',
                    inventory: 'المخزن',
                    inventorysettings: 'إعدادات المخزن',

                    storagekeepingunit: ' كود وحدة التخزين',
                    sku: ' كود وحدة التخزين',
                    updateproductinformation: 'تعديل بيانات ',
                    uploadproductimages: 'رفع صور ',
                    productgallery: 'صور ',
                    noimages: 'لا توجد صور',
                    defaultimagedisclaimer: 'إختر صورة إفتراضية للمنتج بالضغط على الصورة.',
                    updateinventory: 'تعديل المخزن',
                    updateinventorysettings: 'تعديل إعدادات المخزن',
                    minimumquantity: 'الحد الأدنى من الكمية',
                    defaultpricing: 'التسعير الاساسى',
                    optional_ifthereisanysale_: 'إختياري (في حالة وجود خصم)',
                    updatepricing: 'تعديل التسعير',
                    totalquantity: 'الكمبة الكلية',
                    orderedquantity: 'الكمية المطلوبة',
                    currentquantity: 'الكمية الحالية',
                    updatequantity: 'Update Quantity',
                    newquantity: 'الكمية الجديدة',
                    changetype: 'تغيير النوع',
                    updatequantity: 'تحديث الكمية',
                    product: 'المنتج',
                    // Options
                    variantsku: 'وحدة تخزين المتغير',
                    addoptionsdisclaimer: 'من فضلك اضف إختيارات المنتج',
                    productoptions: 'إختيارات المنتج',
                    addproductoptions: 'إضافة إختيارات المنتج',
                    editproductoptions: 'تعديل إختيارات المنتج',
                    addoption: 'إضافه الإختيار',
                    editoption: 'تعديل الإختيار',
                    optionname: 'إسم الإختيار',
                    optionvalue: 'المتغير',
                    optionvalues: 'المتغيرات',
                    productvariatname: 'إسم المتغير',
                    productvariatoptions: 'إختيارات المتغير',
                    pressentertosubmitvalues: 'اضغط Enter لإضافة الإختيارات',
                    waittillvalueisadded: 'من فضلك إنتظر حتى يتم إضافة الإختيارات',
                    pleaseaddoptionnameandvalues: 'من فضلك اضف الإختيارات و المتغيرات',
                    optionsaleprice: 'سعر الخصم للإختيار',
                    optionprice: 'سعر الإختيار',
                    // Variants
                    enablevariants: 'تفعيل الإختيارات',
                    disablevariants: 'تعطيل الإختيارات',
                    novariantsyet: 'لم يضاف اى متغيرات بعد',
                    choosevariantimage: 'إختر صوره المتغير',
                    variantimage: 'صوره المتغير',
                    variants: 'المتغيرات',
                    variants2: 'متغيرات',
                    variantpricing: 'تسعير المتغير',
                    variantprice: 'سعر المتغير',
                    variantsaleprice: 'سعر الخصم للمتغير',
                    updatevariant: 'تحديث المتغير',
                    addvariant: 'إضافة المتغير',
                    addproductvariants: 'إضافة متغيرات المنتج',
                    editproductvariants: 'تحديث متغيرات المنتج',
                    currentvariant: 'المتغير الحالى',

                    noproductimages: 'لا توجد صور للمنتج',
                    noproductimagesdisclaimer: 'من فضلا اضف صور للمنتج اولا',

                    // Collections
                    choosecollection: 'إختر المجموعة',
                    allcollections: 'جميع المجموعات',
                    collectiondescription: 'شرح المجموعة',
                    addnewcollection: 'إضافة مجموعة جديدة',
                    nocollectionsyet: 'لم يتم إضافة مجموعات بعد',
                    collectioninformation: 'بيانات المجموعة',
                    collectionname: 'إسم المجموعة',
                    collectionlogo: 'شعار المجموعة',
                    collectionproducts: 'منتجات المجموعة',
                    completecollectioninfo: 'من فضلك اكمل باقى بيانات المجموعة اولا',
                    uploadlogo: 'ارفع الشعار',
                    addcollection: 'إضافة المجموعة',
                    editcollection: 'تعديل المجموعة',
                    deletecollection: 'حذف المجموعة',
                    numberofproducts: 'عدد المنتجات',

                    // Orders & Customers
                    addressandshipping: 'العنوان و الشحن',
                    doyouwanttodeletecustomer: 'هل تريد حذف هذا العميل؟',
                    logs: 'السجلات',
                    updateorderpayment: 'تحديث طريقة الدفع',
                    orderitems: 'منتجات الطلب',
                    ordersummary: 'ملخص الطلب',
                    updateaddress: 'تحديث العنوان',
                    updatestatus: 'تحديث الحالة',
                    trackactivity: 'نشاط تتبع الطلب',
                    viewordersnotauth: 'عرض الطلبات ليس مسموحا',
                    noordersyet: 'لا يوجد طلبات',
                    resultsfromsearch: 'النتائج من البحث',
                    orderactivity: 'نشاط تتبع الطلب',
                    searchbyvendors: 'البحث عن طريق الموردين',
                    totalspent: 'إجمالى الصرف',
                    numberoforders: 'عدد الطلبات',
                    orderstatus: 'حالة الطلب',
                    chooseorderstatus: 'اختر حالة الطلب',
                    customerhavenoordersyet: 'لا يوجد طلبات',
                    noorderbythiscourier: 'لا يوجد طلبات من هذا المندوب',
                    previousorders: 'الطلبات السابقه',
                    paymentfulfillstatus: 'حالة الدفع و الإكتمال',
                    customerinformation: 'بيانات العميل',
                    shippingaddress: 'عنوان الشحن',
                    billingaddress: 'عنوان وصول الفواتير',
                    allorders: 'جميع الطلبات',
                    choosecustomer: 'إختر العميل',
                    customer: 'عميل',
                    guest: 'زائر',
                    chooseoptions: 'إختر',
                    havenotaddedproducts: 'لم يضاف منتجات بعد',
                    addquantity: 'اضف الكمية',
                    quantity: 'الكمية',
                    addneworder: 'إضافة طلب جديد',
                    addorder: 'إضافة الطلب',
                    filterbypaymentstatus: 'تصفيه حسب حالة الدفع',
                    filterbyfulfillmentstatus: 'التصفية حسب حالة إكتمال الطلب',
                    searchordernum: 'البحث عن طريق رقم الطلب',
                    customername: 'إسم العميل',
                    items: 'العناصر',
                    orderdetails: 'تفاصيل الطلب',
                    choosepaymentstatus: 'إختر حالة الدفع',
                    choosefulfillmentstatus: 'إختر حالة إكتمال الطلب',
                    fulfillorder: 'تكمله الطلب',
                    cancelfulfillment: 'إلغاء تكمله الطلب',
                    cancelfulfillmentconfirm: 'هل تريد إلغاء إكتمال هذا الطلب؟',
                    editfulfillmentstatus: 'تعديل حالة إكتمال الطلب',
                    dependonoption: 'على حسب الإختيار',
                    orderno: 'رقم الطلب',
                    ordernumber: 'رقم الطلب',
                    item: 'المنتج',
                    subtotal: 'المبلغ',
                    shippingrate: 'تكلفه الشحن',
                    discount: 'الخصم',
                    paidamount: 'المدفوع',
                    editorderstatus: 'تعديل حالة الطلب',
                    deleteorderstatus: 'حذف حالة الطلب',
                    addorderstatus: 'إضافه حالة للطلب',
                    titleinen: 'العنوان باللغه الإنجليزيه',
                    titleinar: 'العنوان باللغه العربيه',
                    bgcolor: 'لون الخلفية',
                    staff: 'للموظفبن',
                    customers: 'للعملاء',
                    forstaff: 'للموظفبن',

                    forcustomers: 'للعملاء',
                    additems: 'إضافة منتجات',
                    additem: 'إضافة منتج',
                    removeitem: 'حذف المنتج',
                    edititem: 'تعديل المنتج',
                    customernotes: 'ملحوظات العميل',
                    ordertrackingactivity: 'نشاط تتبع الطلب',
                    orderpaidstatusupdated: 'تم تعديل حالة الدفع',
                    deleteorder: 'حذف الطلب',
                    removecustomer: 'حذف العميل',
                    removecustomerconfirmation: 'هل تريد حذف هذا العميل؟',
                    numberoforders: 'الطلبات',
                    totalspending: 'الإنفاق الكلي',
                    youhavenotaddedanycustomersyet: 'لم يضاف أي عميل بعد',
                    addnewcustomer: 'إضافة عميل جديد',
                    editcustomer: 'تعديل بيانات العميل',
                    allcustomers: 'جميع العملاء',
                    deletecustomer: 'حذف العميل',
                    deletewarningtext: 'تحذير: سوف يتم حذف جميع بيانات العميل و الطلبات الخاصه به ',
                    showcustomerinfo: 'عرض بيانات العميل',
                    youhavenptaddedproductsinorder: 'لم يضاف منتجات فى الطلب بعد',
                    doyouwanttodelete: 'هل تريد حذف ',
                    fromordernum: 'من الطلب رقم',
                    order: 'الطلب',

                    // Promocodes
                    promocode: 'كود الخصم',
                    addpromocode: 'إضافة كود الخصم',
                    youhavenocouponsyet: 'لم يضاف اكواد خصم',
                    couponname: 'إسم الكود',
                    usage: 'الاستخدام',
                    // off: 'خصم',
                    doyouwanttodeletethiscoupon: 'هل تريد حذف كود الخصم؟',
                    editpromocode: 'تعديل الكود',
                    deletepromocode: 'حذف كود الخصم',

                    // Policies
                    privacypolicy: 'شروط الخصوصية',
                    privacypolicycontent: 'محتوى صفحه الشروط الخصوصيه',
                    termsandconditions: 'الشروط والأحكام',
                    refundpolicy: 'شروط الاسترجاع',
                    shippingpolicy: 'شروط الشحن',
                    policyinformation: 'تفاصيل الخصوصية',
                    deletepolicytranswarningtext: 'هل تريد حذف ترجمه؟',
                    generatetemplate: 'إنشاء نموذج',

                    // Institute Settings
                    storeinformation: 'بيانات المتجر',
                    storename: 'إسم المتجر',
                    storetype: 'نوع المتجر',
                    storeindustry: 'التخصص/المجال',
                    storelogo: 'شعار المتجر',
                    storefavouriteicon: 'الايقونه المفضلة للمتجر',
                    paymentsettings: 'إعدادات طرق الدفع',
                    updatecontactinformation: 'تعديل البيانات',
                    updatestoreinformation: 'تعديل بيانات المتجر',
                    updatestorelogo: 'تغيير شعار المتجر',
                    updatestorefavicon: 'تغيير الايقونه المفضله',
                    contactinfodesc: 'البيانات التى سوف يتواصل بها العملاء معك',
                    // Signup data
                    addnewdata: 'إضافة بيانات جديده',
                    editsignupdata: 'تعديل البيانات',
                    deletesignupdata: 'حذف البيانات',
                    signupcustomerinfo: 'بيانات تسجيل العميل',

                    // Payment
                    payment: 'الدفع',
                    deletepaymentmethod: 'حذف طريقه الدفع',
                    cod: 'الدفع عند الإستلام',
                    paymentmethod: 'طريقه الدفع',
                    paymentmethods: 'طرق الدفع',
                    onlinepaymentsettings: 'إعدادات الدفع الإلكترونى',
                    emailsettings: 'إعدادات البريد الإلكترونى',
                    choosepaymentmethod: 'إختر طريقه الدفع',
                    integrationinstructions: 'تعليمات الدمج',
                    paymenthassuccessfullydeleted: 'تم حذف طريقه الدفع',
                    doyouwanttodeletepaymentmethod: 'هل تريد حذف عملية الدفع ؟',
                    deletepayment: 'حذف عملية الدفع',
                    youhavenotaddedanypaymentmethodsyet: 'لم يضاف عمليات الدفع بعد',
                    checked: 'تم تحديدها',
                    notchecked: 'لن تحدد',
                    chosenpaymentmethods: 'إختيارات عمليات الدفع',
                    emailsettingscontractinformation: `يعني تحديد "نعم" أن عملائك لن يتلقوا تأكيدات بالبريد الإلكتروني للمعاملات المكتملة ، أو المستردة ، أو التي تم إلغاؤها ، حتى لو كانت المنصة قد مكنتهم على الصعيد العالمي. لا يزال بإمكان العملاء تلقي رسائل تأكيد بالبريد الإلكتروني من المزود. من المفيد تحديد "نعم" إذا أرسلت بالفعل تأكيدًا لمعاملة إلى عملائك.`,
                    usesandboxprovidercomment:
                        'إذا حددت استخدام وضع الحماية للموفر ، فسيتم إرسال جميع المدفوعات إلى مجال وضع الحماية لهذا الموفر ، وإذا لم يتم تحديدها ، فسيتم إرسال المدفوعات إلى مجال التشغيل المباشرة الخاصة بالموفر.',
                    choosecountry: 'إختر البلد',
                    // Fawry
                    fawrydescription: `تأكد من أن لديك حساب مدير Fawry خاص بك لتعيين عنوان الurl التالي كعنوان URL لإشعار الدفع (عنوان URL لمعاودة الاتصال):
                           <br>/https://moneyhash.io/providers/fawry/webhook`,
                    providedbyfawrypayteamduringtheaccountsetup: 'يقدمها فريق fawry pay أثناء إنشاء الحساب',
                    // Paymob
                    paymobinstructions: `تأكد من إضافة الروابط التاليه فى طرق دمج الدفع فى لوحه التحكم الخاصه ب Paymob Accept:
                             <br>https://moneyhash.io/providers/paymob/webhook/processed/ (in Transaction processed callback)
                             <br>https://moneyhash.io/providers/paymob/webhook/response/ (in Transaction response callback)`,
                    mobilewalletinputcomment: 'يجب إضافة +2 إلى رقم الهاتف ؛ مثال: +20011111111',

                    // Cow pay
                    cowpaycodeinputcomment: 'يقدمها فريق Cowpay أثناء إنشاء الحساب',
                    cowpaytokencomment: 'رمز المصادقة الخاص بالتدريج أو الإنتاج بناءً على مجال use_provider_sandbox',
                    cowpaycheckboxcomment:
                        'إذا تم تحديده ، فسيتم إرسال جميع المدفوعات إلى بيئة وضع الحماية الخاصة بهذا الموفر ، وإذا لم يتم تحديدها ، فسيتم إرسال المدفوعات إلى البيئة المباشرة للمزود.',
                    // Pay tabs
                    paytabsinputcomment: 'يتم الحصول عليها من لوحة معلومات الموفر',

                    // Analytics
                    monthly: 'شهري',
                    connect: 'إتصال',
                    connectto: 'الإتصال ب',
                    disconnect: 'قطع الإتصال',
                    id: 'الرمز التعريفي ل',
                    id2: 'الرمز التعريفي',
                    allowsyoutotrackwebsiteperformanceandcollectvisitorinsights: 'يسمح لك بتعقب أداء الموقع وجمع الزيارات أو الأفكار. ',
                    allowsyoutomeasureyouradvertisingROIandtrackyoursocialnetworkingsitesandapplications: 'يسمح لك بقياس عائد الاستثمار الإعلاني وتتبع مواقع الشبكات الاجتماعية والتطبيقات الخاصة بك.',
                    allowsyoutomeasuretheeffectivenessofyouradvertising: 'يسمح لك بقياس فعالية الإعلان الخاص بك.',

                    // General Settings
                    userinformation: 'البيانات الشخصية',
                    changeemail: 'تغيير البريد الإلكتروني',
                    subscriptiondetails: 'معلومات عن الإشتراك',
                    currentemail: 'البريد الحالى',
                    newemail: 'البريد الإلكتروني الحديث',
                    oldpassword: 'كلمة السر الحالية',
                    newpassword: 'كلمة السر الحديثة',
                    errorinchangingemail: 'خطأ في تجديد البريد الإلكتروني',
                    errorinchangingpassword: 'خطأ في تجديد كلمة السر',
                    serveremailchangeerror: 'خطأ فى تجديد البريد الإلكتروني،  بالرجاء الإتصال بفريق Tabex.',
                    serverpasswordchangeerror: 'خطأ فى تجديد كلمة السر،  بالرجاء الإتصال بفريق Tabex.',
                    upgradeuserinformation: 'تحديث البيانلت الشخصية',
                    errorinaddinginformation: 'خطأ في إضافة المعلومات',
                    errorineditinginformation: 'خطأ في تعديل المعلومات',
                    dataupadated: 'تم تعديل البيانات',
                    servererrorinaddinginformation: 'خطأ فى إضافة المعلومات،  بالرجاء الإتصال بفريق Tabex.',
                    servererrorineditinginformation: 'خطأ فى تعديل المعلومات،  بالرجاء الإتصال بفريق Tabex.',
                    sendverificationcode: 'إرسال رمز التحقق',
                    verificationcoderequested: 'تم طلب رمز التحقق',
                    codeverified: 'تم تأكيد رمز التحقق',
                    youremailhaschangesuccessfully: 'تم تغيير البريد الإلكتروني',
                    pleaseinserttheverificationcode: 'برجاء إدخال رمز التحقق',
                    verificationcode: 'إدخال رمز التحقق',
                    packagename: 'اسم الباقه',
                    duration: 'المدة',
                    fees: 'الرسوم',
                    annualfees: 'رسوم سنوية',
                    maximuminstitutes: 'الحد الأقصى من المنشات',
                    mobileapplication: 'تطبيق للهاتف',
                    emailalreadyexists: 'هذا البريد غير مختلف',
                    changepassword: 'تغيير كلمة السر',
                    passwordalreadyexists: 'كلمة السر غير مختلفة',
                    oldpasswordisincorrect: 'كلمة سر غير صحيحة',
                    updateuserinformation: 'تحديث البيانات',

                    // Uploader
                    chosenfiles: 'الملفات المختارة',
                    uploadfiles: 'رفع الملفات',
                    uploaderdropdowntext: 'اختر او اسحب الملفات او اضغط للتصفح',

                    // Mobile Application
                    notificationslogs: 'سجلات التنبيهات',
                    mobileapplicationusers: 'مستخدمين تطبيق الهاتف',
                    loggedinusers: 'المستخدمين الحاليين',
                    alldevices: 'جميع الاجهزه',
                    notificationid: 'رمز التنبيه',
                    banned: 'محذور',
                    loggedin: 'مسجل',
                    lastseen: 'اخر ظهور',
                    loggedindevicetype: 'الجهاز المسجل',
                    mob_deviceid: 'الرمز التعريفى للجهاز',
                    notificationallowed: 'السماح بالتنبيهات',
                    sendnotification: 'إرسال تنبيه',
                    banemail: 'حظر البريد الإلكترونى',
                    notificationcontent: 'محتوى التنبيه',
                    notificationtitle: 'عنوان التنبيه',
                    sendnotificationto: 'إرسال التنبيه الى',
                    notloggedin: 'غير مسجل',
                    notallowed: 'غير مسموح',
                    allowed: 'مسموح',
                    website: 'الموقع الإلكترونى',

                    // Users
                    adduser: 'إضافة مستخدم جديد',
                    edituser: 'تعديل بيانات المستخدم',
                    deleteuser: 'حذف المستخدم',
                    youhavenousersyet: 'لا يوجد مستخدمين',

                    // SMS SERVICE
                    smsserviceinformation: 'بيانات خدمه الرسائل',
                    senderid: 'Sender ID',
                    addsenderid: 'إضافة Sender ID',
                    thissenderid: 'this sender ID',
                    deletesenderid: 'حذف Sender ID',
                    sendername: 'اسم المرسل',
                    senderstatus: 'الحالة',
                    taxpaper: 'Tax Paper',
                    instpaper: 'Inst Paper',
                    balance: 'الرصيد',
                    requestbalance: 'طلب رصيد',
                    numberofmsgs: 'عدد الرسائل',
                    smslogs: 'سجلات الرسائل',
                    logid: 'Log ID',
                    smsstatus: 'حالة الرساله',
                    reason: 'السبب',
                    smscontent: 'محتوى الرساله',
                    requestedbalance: 'الرصيد المطلوب',
                    noofmsgs: 'عدد الرسائل',
                    balancelogs: 'سجلات الرصيد',
                    balancelogid: 'Balance Log ID',
                    nosmsrequested: 'عدد الرسائل المطلوبة',
                    addsernderid: 'إضافة Sender ID',
                    addbalance: 'إذافة رصيد',
                    sendmessage: 'إرسال رسالة',
                    smsbody: 'محتوى الرسالة',
                    receiverphonenumber: 'رقم هاتف المستقبل',

                    // Vendors
                    allvendors: 'جميع الموردين',
                    vendorname: 'إسم المورد',
                    location: 'المكان',
                    addvendor: 'إضافة مورد',
                    deletevendor: 'حذف مورد',
                    editvendorinfo: 'تعديل بيانات المورد',
                    doyouwanttodeletethisvendor: 'هل تريد حذف هذا المورد؟',

                    // Categories
                    nocollections: 'لا توجد مجموعات',
                    categories: 'الفئات',
                    addcategory: 'إضافة فئة',
                    deletecategory: 'حذف الفئة',
                    editcategory: 'تعديل الفئة',
                    categoryname: 'إسم الفئة',
                    collections: 'المجموعات',
                    visibilitystatus: 'حالة الظهور',
                    visibleinfilter: 'حالة الظهور فى الفلتر',
                    categorylogo: 'شعار الفئة',
                    categoryinfo: 'بيانات الفئة',
                    categorydescription: 'الشرح',
                    categorystatus: 'حالة الفئة',
                    visibleinfilter: 'الظهور فى الفلتر',
                    visibletocustomers: 'الظهور للعملاء',
                    categorycollections: 'مجموعات الفئة',
                    chosencollections: 'المجموعات المختارة',

                    // Notifications
                    servererrorupdatevendors: 'خطأ فى تحديث الموردين. برجاء الإتصال بفريق Tabex',
                    vendorsupdatesuccess: 'تم تحديث الموردين',
                    servererrorupdatesecuritygp: 'خطأ فى تحديث مستوى الحماية. برجاء الإتصال بفريق Tabex',
                    securitygpupdatedsuccess: 'تم تحديث مستوى الحماية',
                    // Products Notifications
                    optionsupdatessuccess: 'تم تحديث الإختيارات',
                    servererrorupdateoptions: 'خطأ فى تحديث المتغيرات برجاء الإتصال بفريق Tabex',
                    valuealreadyexists: 'النتغير موجود بالفعل',
                    pleasewritevaluename: 'من فضلك اكتب إسم المتغير',
                    pleasewritevalues: 'من فضلك اكتب المتغيرات',
                    pleasewriteoptionname: 'من فضلك اكتب إسم الإختيار',
                    productaddedsuccess: 'تم إضافة المنتج',
                    servererroraddingproduct: 'خطأ فى إضافة المنتج، برجاء الإتصال بفريق Tabex',
                    optionsupdatedsuccess: 'تم تغيير إختيارات المنتج',
                    servererroroptionsupdated: 'خطأ فى تحديث إختيارات المنتج، برجاء الإتصال بفريق Tabex',
                    deleteproductsuccess: 'تم حذف المنتج',
                    servererrordeleteproduct: 'خطأ فى حذف المنتج، برجاء الإتصال بفريق Tabex',
                    errorupdateproduct: 'خطأ فى تحديث المنتج، برجاء الإتصال بفريق Tabex',
                    // Images
                    imageuploadedsuccess: 'تم رفع الصور(ة) بنجاح',
                    imagedeletedsuccess: 'تم حذف الصورة',
                    servererroruploadingimage: 'خطأ فى رفع الصور(ة). برجاء الإتصال بفريق Tabex',
                    servererrordeleteimage: 'خطأ فى حذف الصورة. برجاء الإتصال بفريق Tabex',
                    // Product Images
                    galleryimagedeletedsuccess: 'تم حذف الصورة',
                    servererrordeletegalleryimage: 'خطأ فى حذف الصورة. برجاء الإتصال بفريق Tabex',
                    defaultimageupdatedsuccess: 'تم تحديث الصورة الاساسية',
                    servererrorupdatedefaultimage: 'خطأ فى تحديث الصورة الاساسية. برجاء الإتصال بفريق Tabex',
                    // Quantity
                    erroraddingquantity: 'خطأ فى إضافة الكمية. برجاء الإتصال بفريق Tabex',
                    // Prod Info
                    prodinfoupdatessuccess: 'تم تعديل بيانات المنتج',
                    servererrorupdateprodinfo: 'خطأ فى تعديل بيانات المنتج. برجاء الإتصال بفريق Tabex',
                    // Choose Collection
                    pleasechoosecollection: 'من فضلك اختر مجموعة',
                    servererrorchangeprodoptionvalues: 'Error in Changing Product Options Values. Please Contact Tabex Team',
                    // Prod Avail
                    productavailupdatesuccess: 'تم تغيير حالة توافر المنتج',
                    servererrorupdateprodavail: 'خطأ فى تغيير حالة توافر المنتج. برجاء الإتصال بفريق Tabex',
                    // Inventory
                    inventoryupdateduccess: 'تم تحديث المخزن',
                    servererrorupdateinventory: 'خطأ فى تحديث المخزن. برجاء الإتصال بفريق Tabex',
                    // Variants
                    statuschangessuccess: 'تم تغيير الحالة',
                    servererrorchangevariantsstatus: 'خطأ فى تغيير الحالة. برجاء الإتصال بفريق Tabex',
                    variantimageupdated: 'تم تحديث صورة المتغير',
                    variantupdatedsuccess: 'تم تحديث بيانات المتغير',
                    servererrorupdatevariant: 'خطأ فى تحديث بيانات المتغير. برجاء الإتصال بفريق Tabex',
                    variantaddedsuccess: 'تم إضافة المتغير',
                    servererroraddvariant: 'خطأ فى إضافة بيانات المتغير. برجاء الإتصال بفريق Tabex',
                    variantdeletedsuccess: 'تم حذف المتغير',
                    servererrordeletevariant: 'خطأ فى حذف المتغير. برجاء الإتصال بفريق Tabex',
                    // Pricing
                    defaultpricingupdatedsuccess: 'تم تحديث السعر',
                    servererrorupdatedefaultpricing: 'خطأ فى تحديث السعر الاساسى. برجاء الإتصال بفريق Tabex',
                    translationsuccessfullysaved: 'تم حفظ الترجمة',
                    policiessavedsuccessfully: 'تم حفظ البيانات بنجاح',
                    servererrorsavepolicy: 'خطأ فى حفظ البيانات، بالرجاء الإتصال بفريق Tabex',
                    // Orders Notifications
                    shippingzonefound: 'تم العثور على منطقة الشحن',
                    promocoderemoved: 'تم حذف كود الخصم',
                    servererrorcheckingzones: 'خطأ فى العثور على منطقة الشحن. الإتصال بفريق Tabex',
                    promocodeapplied: 'تم تطبيق كود الخصم',
                    servererrorapplyingpromocode: 'خطأ فى تطبيق كود الخصم. الإتصال بفريق Tabex',
                    pleasewriteyourpromocode: 'من فضلك ادخل كود الخصم',
                    orderisaddedsuccess: 'تم إضافة الطلب بنجاح',
                    servererroraddingorder: 'خطأ فى إضافة الطلب بالرجاء الإتصال بفريق Tabex',
                    pleaseaddproducts: 'من فضلك اضف منتجات اولا',
                    pleasechoosefulfillmentstatus: 'من فضلك اختر حالة إكتمال الطلب',
                    pleasechoosepaidstatus: 'من فضلك اختر حالة الدفع',
                    pleasechooseorderstatus: 'من فضلك اختر حالة الطلب',
                    itemisaddedsuccess: 'تم إضافة المنتج',
                    itemisdeletedsuccess: 'تم حذف المنتج',
                    orderisdeletedsuccess: 'تم حذف الطلب',
                    statusiseditedsuccess: 'تم تغيير حالة الطلب بنجاح',
                    servererrordeleteorder: 'خطأ فى حذف الطلب. برجاء الإتصال بفريق Tabex',
                    servererrorremoveitem: 'خطأ فى حذف المنتج. برجاء الإتصال بفريق Tabex',
                    quantityisadded: 'تم إضافة الكمية',
                    completestepstoplaceorder: 'اكمل باقى الخطوات لتكمله الطلب',
                    servererroreditstatus: 'خطأ فى تغيير حالة الطلب. برجاء الإتصال بفريق Tabex',
                    pleaseaddquantity: 'من فضلك اضف الكمية',
                    servererrorsearchorders: 'خطأ فى البحث عن الطلب. برجاء الإتصال بفريق Tabex',
                    servererrorupdatingorders: 'خطأ فى تعديل الطلب. برجاء الإتصال بفريق Tabex',
                    orderupdatedsuccess: 'تم تحديث الطلب',
                    servererrorsearchvendors: 'خطأ فى البحث عن المورد. برجاء الإتصال بفريق Tabex',
                    choosecountrystatecity: 'من فضلك إختر البلد و المحافظة و المدينة',
                    doyouwanttodeleteorder: 'هل تريد حذف الطلب',
                    doyouwanttodeleteorderandreturnitems: 'هل تريد حذف الطلب و إرجاع المنتجات',
                    archive: 'أرشيف',

                    servererroreditingorderstatus: 'خطأ فى تحديث حالة الطلب. برجاء الإتصال بفريق Tabex',
                    orderstatusupdatedsuccess: 'تم تحديث حالة الطلب',
                    // Collections Notifications
                    collectionaddedsuccessfully: 'تم إضافة المجموعة',
                    collectiondeletedsuccessfully: 'تم حذف المجموعة',
                    collectioneditedsuccessfully: 'تم تعديل المجموعة',
                    collectionlogoupdatesucess: 'تم تغيير شعار المجموعة',
                    servererroraddingcollection: 'خطأ فى إضافة مجموعة جديده.  برجاء الإتصال بفريق Tabex',
                    servererroreditingcollection: 'خطأ فى تعديل المجموعة.  برجاء الإتصال بفريق Tabex',
                    servererrorupdatecollectionlogo: 'خطأ فى تغيير شعار المجموعة.  برجاء الإتصال بفريق Tabex',
                    itemisdeletedfromcollection: 'تم حذف المنتج من المجموعة',
                    itemisaddedtocollection: 'تم إضافه المنتج فى المجموعة',
                    // Customers Notifications
                    addcustomerservererror: 'خطأ فى إضافة عميل جديد، برجاء الإتصال بفريق Tabex',
                    editcustomerservererror: 'خطأ فى تعديل العميل، برجاء الإتصال بفريق Tabex',
                    deletecustomerservererror: 'خطأ فى حذف العميل، برجاء الإتصال بفريق Tabex',
                    customerhasbeensuccessfullyadded: 'تم إضافة العميل',
                    customerhasbeensuccessfullyedited: 'تم تعديل بيانات العميل',
                    customerhasbeensuccessfullydeleted: 'تم حذف العميل',
                    pleaseaddname: 'يرجى إضافة إسم',
                    pleaseselectbranch: 'من فضلك اختر فرع',
                    // Shipping Notifications
                    servererroraddingzone: 'خطأ فى إضافة منطقه جديده. برجاء الإتصال بفريق Tabex',
                    servererroreditingzone: 'خطأ فى تعديل بيانات المنطقه. برجاء الإتصال بفريق Tabex',
                    servererrordeletingzone: 'خطأ فى حذف المنطقه. برجاء الإتصال بفريق Tabex',
                    zoneeditedsuccess: 'تم تعديل بيانات المنطقه بنجاح',
                    zoneaddedsuccess: 'تم إضافة المنطقه بنجاح',
                    zonedeletedsuccess: 'تم حذف المنطقه بنجاح',
                    allstatesselected: 'تم إختيار جميع المناطق',
                    allstatesunselected: 'تم حذف جميع المناطق',
                    // Branches Notifications
                    brancheditedsuccess: 'تم تعديل بيانات الفرع',
                    branchaddessuccess: 'تم إضافة الغرع',
                    branchdeleted: 'تم حذف الفرع',
                    errorinaddingbranchserver: 'خطأ فى إضافة فرع جديد. برجاء الإتصال بفريق Tabex',
                    erroreditingbranchserver: 'خطأ فى تعديل بيانات الفرع. برجاء الإتصال بفريق Tabex',
                    errordeletingbranchserver: 'خطأ فى حذف الفرع. برجاء الإتصال بفريق Tabex',
                    // Institute Settings Notifications
                    pleasecomleteyourcontactinformation: 'من فضلك اكمل باقى البيانات الشخصيه',
                    contactinfoupdatedsuccess: 'تم تعديل البيانات الشخصيه',
                    servererrorcontactinfo: 'خطأ فى تعديل البيانات الشخصيه. برجاء الإتصال بفريق Tabex',
                    storeinfoupdatedsuccess: 'تم تعديل بيانات المتجر',
                    servererrorstoreinfo: 'خطأ فى تعديل بيانات المتجر. برجاء الإتصال بفريق Tabex',
                    logoaddedsuccess: 'تم إضافة الشعار',
                    erroruploadingfavicon: 'خطأ فى تغيير الايقونه المفضله. برجاء الإتصال بفريق Tabex',
                    faviconupdatedsuccess: 'تم تغيير الايقونه المفضله',
                    erroruploadinglogo: 'خطأ فى تغيير شعار المتجر. برجاء الإتصال بفريق Tabex',
                    pleaseuploadimage: 'من فضلك قم برفع صوره اولا',
                    pleasecompletecompletestoreinfo: 'من فضلك اكمل باقى بيانات المتجر',
                    // Signup data
                    infoaddedsuccess: 'تم الإضافة بنجاح',
                    infoeditedsuccess: 'تم التعديل بنجاح',
                    infoeletedsuccess: 'تم الحذف بنجاح',
                    // Payment Notifications
                    paymentmethodadded: 'تم إضافة عملية الدفع',
                    paymentmethodenabled: 'تم تفعيل عملية الدفع',
                    paymentmethoddisabled: 'تم تعطيل عملية الدفع',
                    servererroraddpaymentmethod: 'خطأ فى إضافة طريقه دفع جديده. برجاء الإتصال بفريق Tabex',
                    servererrordeletepaymentmethod: 'خطأ فى إضافة حذف طريقه الدفع. برجاء الإتصال بفريق Tabex',
                    servererrorchangeactivationpaymentmethod: 'خطأ فى إضافة تغيير الحالة. برجاء الإتصال بفريق Tabex',
                    // Coupons Notifications
                    serveraddpromocodeerror: 'خطأ فى إضافة كود الخصم،  برجاء الإتصال بفريق Tabex.',
                    servereditpromocodeerror: 'خطأ فى تعديل كود الخصم،  برجاء الإتصال بفريق Tabex.',
                    serverdeletepromocodeerror: 'خطأ فى حذف كود الخصم،  برجاء الإتصال بفريق Tabex.',
                    couponaddedsuccess: 'تم إضافة كود الخضم',
                    couponeditedsuccess: 'تم تعديل كود الخصم',
                    coupondeletedsucess: 'تم حذف كود الخصم',
                    // Users Notifications
                    servererroraddinguser: 'خطأ فى إضافة مستخدم جديد. برجاء الإتصال بفريق Tabex',
                    servererroreditinguser: 'خطأ فى تعديل بيانات المستخدم. برجاء الإتصال بفريق Tabex',
                    servererrordeletinguser: 'خطأ فى حذف المستخدم. برجاء الإتصال بفريق Tabex',
                    usereditedsuccess: 'تم تعديل بيانات المستخدم بنجاح',
                    useraddedsuccess: 'تم إضافة المستخدم بنجاح',
                    userdeletedsuccess: 'تم حذف المستخدم بنجاح',
                    // SMS Service Notifications
                    senderidaddedsuccess: 'تم إضافة Sender ID',
                    senderiddeletedsuccess: 'تم حذف Sender ID',
                    servererroraddsenderid: 'خطأ فى إضافة Sender ID. برجاء الإتصال بفريق Tabex',
                    servererrordeletesenderid: 'خطأ فى حذف Sender ID. برجاء الإتصال بفريق Tabex',
                    servererrorsendsms: 'خطأ فى إرسال الرساله. برجاء الإتصال بفريق Tabex',
                    sendsmssentsuccess: 'تم إرسال الرساله',
                    // Mobile Application Notifications
                    notificationsentsucceess: 'تم إرسال التنبيه',
                    servererrorsendnotification: 'خطأ فى إرسال التنبيه. برجاء الإتصال بفريق Tabex',
                    allusersselected: 'تم إختيار جميع العملاء',
                    // Couriers Notifications
                    servererrorupdatecouriers: 'خطأ فى التحديث. برجاء الإتصال بفريق Tabex',
                    couriersupdatedsuccessfully: 'تم التحديث',
                    zonealreadyexists: 'المنطقة توجد بالفعل',
                    deletecourierconfirmation: 'هل تريد حذف المندوب؟',
                    // Categories Notifications
                    categoriesupdatedsuccess: 'تم تحديث الفئات',
                    servererrorerrorupdatingcategories: 'خطأ فى تحديث الفئات. برجاء الإتصال بفريق Tabex',
                    categoryaddedsuccess: 'تم إضافة الفئة',
                    servererrorerroraddingcategory: 'خطأ فى إضافة الفئة. برجاء الإتصال بفريق Tabex',
                    errordeletecategory: 'خطأ فى حذف الفئة. برجاء الإتصال بفريق Tabex',

                    // Paid and fulfilment Status
                    itemfulfillmentstatus: 'حالة إكتمال المنتج',
                    fulfillmentstatus: 'حالة إكتمال الطلب',
                    paidstatus: 'حالة الدفع',
                    paid: 'مدفوع',
                    unpaid: 'غير مدفوع',
                    canceled: 'ملغي',
                    fulfilled: 'مكتمل',
                    unfulfilled: 'غير مكتمل',
                    partiallyfulfilled: 'مكتمل جزئياً',
                    enabled: 'مفعل',
                    disabled: 'غير مفعل',
                    // ====================================================================================================
                    youdonthavesubscriptionsyet: 'ليس لديك إشتركات بعد',
                    subscribenow: 'إشترك الآن',
                    month: 'شهر/ شهور',
                    timestmap: 'الطابع الزمني',
                    subsriptionstarts: 'الحجز يبدأ من',
                    paidwith: 'الدفع عن طريق',
                    offerprice: 'سعر العرض',
                    finalprice: 'السعر النهائي',
                    packageprice: 'سعر الحزمة',
                    package: 'حزمة',
                    itemsandreservations: 'العناصر والحجوزات',
                    system: 'النظام',
                    reservationsettings: 'إعدادات الحجز',
                    webapp: 'لوحة التحكم',
                    sat: 'السبت',
                    sun: 'الأحد',
                    mon: 'الإثنين',
                    tues: 'الثلثاء',
                    wednes: 'الأربعاء',
                    thurs: 'الخميس',
                    fri: 'الجمعة',
                    socialmedia: 'وسائل التواصل الاجتماعي',
                    currencies: 'العملات',
                    addcurrency: 'إضافة عملة جديدة',
                    hide: 'إخفاء',
                    show: 'إظهار',
                    editcurrency: 'تعديل العملة',
                    deletecurrency: 'إزالة العملة',
                    visibilityinfilter: 'حالة الرؤية في المرشح',
                    vendors: 'الموردين',
                    subscriptions: 'الإشتركات',
                    institueactivitystatus: 'حالة نشاط المعهد',
                    lastsubscriptiondate: 'آخر معاد للحجز',
                    reservations: 'الحجوزات',
                    reservationtype: 'نوع الحجز',
                    minutes: 'دقائق',
                    days: 'ايام',
                    startson: 'يبدأ فى',
                    endon: 'ينتهى فى',
                    maxreservation: 'اقصى عدد حجوزات',
                    maxdays: 'اقصى عدد ايام الحجز',
                    maxresperday: 'اقصى عدد حجوزات فى اليوم',
                    slotdifference: 'فرق التوقيت بين الحجوزات',
                    overridereservations: 'تجاوز الحجزات السابقة',
                    yes: 'نعم',
                    no: 'لا',
                    service: 'خدمة',
                    productindex: 'ترتيب المنتج',
                    index: 'ترتيب ',
                    websiteviews: 'زيارات الموقع',
                    mobappviews: 'زيارات التطبيق',
                    amount: 'الاجمالي',
                    thisreceiptisrequiredforanexchangeorrefundtransaction: 'هذا الإيصال مطلوب لاي معاملة الاستبدال أو الاسترداد',
                    thermalprint: 'طباعة حرارية',
                    productfeatures: 'مواصفات المنتج',
                    featurename: 'اسم الميزة',
                    featureindex: 'ترتيب الميزة',
                    addfeature: 'إضافة ميزة',
                    editfeature: 'تعديل الميزة',
                    featuretype: 'نوع الميزة',
                    checkbox: 'مربع الاختيار',
                    number: 'رقم',

                    dynamicforms: 'النماذج المتغيرة',
                    addnewform: 'إضافة نموذج جديد',
                    answers: 'الإجابات',
                    submittedat: 'قدمت في',
                    addform: 'إضافة نموذج',
                    editform: 'تعديل النموذج',
                    formtitleen: 'عنوان النموذج En',
                    formtitlear: 'عنوان النموذج Ar',
                    // Login
                    welcomeback: 'اهلا بك',
                    totabexseries: 'فى سلسله Tabex',
                    login: 'تسجيل الدخول',
                    forgotpassword: 'هل نسيت كلمة السر؟',
                    wrongemailorpassword: 'يوجد خطأ في البريد الإلكتروني أو كلمة السر',

                    tickets: 'تذاكر',
                    createticket: 'إنشاء تذكرة',
                    createemergencyticket: 'إنشاء تذكرة طارئة',
                    addanattachment: 'إضافة مرفق',
                    Thefileattachmustbeafile: 'يجب أن يكون الملف المرفق ملفًا من النوع: jpeg ، png ، jpg ، gif ، svg ، mp4 ، pdf.',
                    reply: 'الرد',
                    describeyourissueasclearlyasyoucan: 'صف مشكلتك بوضوح قدر الإمكان',
                    createanewemergencysupportticket: 'إنشاء تذكرة دعم طارئة جديدة',
                    createanewsupportticket: 'إنشاء تذكرة دعم جديدة',
                    tickettype: 'نوع التذكرة',
                    dashboardorders: 'لوحة التحكم - الطلبيات',
                    dashboardproducts: 'لوحة التحكم - المنتجات',
                    dashboardgeneral: 'لوحة التحكم - عام',
                    websitetemplate: 'قالب الموقع الإلكتروني',
                    websitefunctions: 'وظائف الموقع الإلكتروني',
                    mobileapplicationtemplate: 'قالب التطبيق المحمول',
                    mobileapplicationfunctions: 'وظائف التطبيق المحمول',
                    other: 'أخرى',
                    subject: 'الموضوع',
                    ticketsubject: 'موضوع التذكرة',
                    pleasedescribeyourissue: 'يرجى وصف مشكلتك',

                    emergency: 'طارئ',
                    pending: 'قيد الانتظار',
                    inprogress: 'قيد التنفيذ',
                    resolved: 'تم الحل',
                    noticketsyet: 'ليس لديك تذاكر بعد',
                    tabexsupport: 'دعم تابكس',

                    workingdays: 'أيام العمل',
                    workinghours: 'ساعات العمل',
                    sundaytothursday: 'من الأحد إلى الخميس',
                    fromtenamtosixpm: 'من 10:30 صباحًا إلى 6 مساءً',

                    productextras: 'إضافات المنتج',
                    packagelist: 'قائمة الحزم',
                    priceperpiece: 'السعر لكل قطعة',
                    virtualtryon: 'تجربة افتراضية',
                    imagetype: 'نوع الصورة',
                    tryonplace: 'مكان التجربة',
                    searchbystatus: 'البحث حسب الحالة',
                },
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'ar',
            });
        } else {
            this.setState({
                langdetect: 'en',
            });
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }
        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
